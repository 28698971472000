import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format, addDays, isBefore, isAfter, differenceInDays, startOfDay } from 'date-fns';
import { Calendar, Shield, AlertCircle, Loader2 } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { sendEmail } from '../lib/email';
import { usePrice } from '../utils/currency';
import type { Listing } from '../types';
import toast from 'react-hot-toast';

interface BookingWidgetProps {
  listing: Listing;
  onBookingSubmit: (startDate: Date, endDate: Date) => Promise<void>;
}

const BookingWidget: React.FC<BookingWidgetProps> = ({ listing, onBookingSubmit }) => {
  const { user } = useAuth();
  const { format: formatPrice } = usePrice();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);

  const handleDayClick = (day: Date) => {
    const normalizedDay = startOfDay(day);
    if (!startDate || (startDate && endDate)) {
      setStartDate(normalizedDay);
      setEndDate(undefined);
      setFocusedInput('endDate');
    } else {
      if (isBefore(normalizedDay, startDate)) {
        setStartDate(normalizedDay);
        setEndDate(undefined);
        setFocusedInput('endDate');
      } else {
        setEndDate(normalizedDay);
        setFocusedInput(null);
        setShowCalendar(false);
      }
    }
  };

  const handleClearDates = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setFocusedInput('startDate');
    setHoverDate(null);
  };

  const handleDayHover = (day: Date | undefined) => {
    if (day && startDate && !endDate) {
      setHoverDate(day);
    }
  };

  const calculateTotalPrice = () => {
    if (!startDate || !endDate) return null;

    const days = differenceInDays(endDate, startDate) + 1;
    const basePrice = listing.pricingSchedule.dailyRate * days;
    const securityDeposit = listing.securityDeposit?.amount || 0;
    const specialRate = listing.specialRates?.amount || 0;
    const total = basePrice + securityDeposit + specialRate;

    return {
      days,
      basePrice,
      securityDeposit,
      specialRate,
      total
    };
  };

  const handleSubmit = async () => {
    if (!user) {
      toast.error('Please sign in to make a booking');
      setShowAuthModal(true);
      return;
    }

    if (!startDate || !endDate) {
      toast.error('Please select both start and end dates');
      return;
    }

    try {
      setIsSubmitting(true);
      
      if (!startDate || !endDate) {
        throw new Error('Please select both start and end dates');
      }

      await onBookingSubmit(startDate, endDate);
      
      // Send booking request email to owner
      if (listing.owner.email) {
        await sendEmail({
          to: listing.owner.email,
          template: 'BOOKING_REQUEST',
          data: {
            renterName: `${user.firstName} ${user.lastName}`,
            bagModel: listing.bagSpecs.model,
            startDate: format(startDate, 'PPP'),
            endDate: format(endDate, 'PPP'),
            totalPrice: formatPrice(pricing?.total || 0),
            acceptUrl: `${window.location.origin}/dashboard?booking=${listing.id}&action=accept`,
            declineUrl: `${window.location.origin}/dashboard?booking=${listing.id}&action=decline`
          }
        });
      }
      
      toast.success('Booking request sent successfully!');
    } catch (error) {
      console.error('Booking error:', error);
      toast.error(error.message || 'Failed to create booking');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pricing = calculateTotalPrice();

  return (
    <div className="bg-white rounded-lg p-6 relative border border-gray-200 shadow-lg">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Book Now</h2>

      {/* Date Inputs */}
      <div className="grid grid-cols-2 gap-2 mb-4">
        <div 
          onClick={() => {
            setShowCalendar(true);
            setFocusedInput('startDate');
          }}
          className="bg-gray-50 p-3 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors border border-gray-200"
        >
          <div className="text-xs text-gray-500 mb-1">CHECK-IN</div>
          <div className="text-gray-900">
            {startDate ? format(startDate, 'MM/dd/yyyy') : 'Add date'}
          </div>
        </div>
        <div 
          onClick={() => {
            setShowCalendar(true);
            setFocusedInput('endDate');
          }}
          className="bg-gray-50 p-3 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors border border-gray-200"
        >
          <div className="text-xs text-gray-500 mb-1">CHECKOUT</div>
          <div className="text-gray-900">
            {endDate ? format(endDate, 'MM/dd/yyyy') : 'Add date'}
          </div>
        </div>
      </div>

      {showCalendar && (
        <div 
          className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg p-4 shadow-xl z-10 border border-gray-200"
          role="dialog"
          aria-label="Date picker"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">
              {focusedInput === 'startDate' ? 'Select check-in date' : 'Select check-out date'}
            </h3>
            <button
              onClick={() => setShowCalendar(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          <DayPicker
            mode="range"
            selected={{
              from: startDate,
              to: endDate || hoverDate
            }}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayHover}
            onDayMouseLeave={() => setHoverDate(null)}
            disabled={[
              { before: new Date() },
              { after: addDays(new Date(), 90) }
            ]}
            modifiers={{
              booked: [new Date(2024, 2, 15), new Date(2024, 2, 16)],
              highlighted: startDate && !endDate && hoverDate ? {
                from: startDate,
                to: hoverDate
              } : undefined
            }}
            modifiersStyles={{
              booked: { backgroundColor: '#FEE2E2', color: '#991B1B' },
              highlighted: { backgroundColor: '#E5E7EB' }
            }}
            className="!bg-white rounded-lg border border-gray-200"
          />
          <div className="flex justify-between mt-4">
            <button
              onClick={handleClearDates}
              className="text-gray-500 hover:text-gray-700 text-sm"
            >
              Clear dates
            </button>
            <button
              onClick={() => setShowCalendar(false)}
              className="text-primary-600 hover:text-primary-700 text-sm font-medium"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Pricing Breakdown */}
      {pricing && (
        <div className="space-y-3 border-t border-gray-200 pt-4 mt-4">
          <div className="flex justify-between text-gray-600">
            <div className="flex items-center">
              <span>{formatPrice(listing.pricingSchedule.dailyRate)} × {pricing.days} days</span>
            </div>
            <span>{formatPrice(pricing.basePrice)}</span>
          </div>
          
          {listing.securityDeposit?.amount && (
            <div className="flex justify-between text-gray-600">
              <div className="flex items-center">
                <span>Security Deposit</span>
              </div>
              <span>{formatPrice(listing.securityDeposit.amount)}</span>
            </div>
          )}
          
          {listing.specialRates?.amount && (
            <div className="flex justify-between text-gray-600">
              <div className="flex items-center">
                <span>Special Rate</span>
              </div>
              <span>{formatPrice(listing.specialRates.amount)}</span>
            </div>
          )}
          
          <div className="flex justify-between text-gray-900 font-semibold pt-3 border-t border-gray-200">
            <span>Total</span>
            <span>{formatPrice(pricing.total)}</span>
          </div>
          {startDate && endDate && (
            <p className="text-sm text-gray-500 mt-2">
              You won't be charged yet
            </p>
          )}
        </div>
      )}

      <button
        onClick={handleSubmit}
        disabled={!startDate || !endDate || isSubmitting}
        className="w-full bg-primary-600 hover:bg-primary-700 text-white py-3 rounded-lg transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2 mt-6"
      >
        {isSubmitting ? (
          <>
            <Loader2 className="w-5 h-5 animate-spin" />
            <span>Processing...</span>
          </>
        ) : (
          <span>Request Booking</span>
        )}
      </button>

      <div className="mt-6 space-y-3 text-sm text-gray-600">
        <div className="flex items-center space-x-2">
          <Calendar className="w-4 h-4" />
          <span>Free cancellation up to 24 hours before pickup</span>
        </div>
        <div className="flex items-center space-x-2">
          <Shield className="w-4 h-4" />
          <span>Insurance included in rental price</span>
        </div>
      </div>
    </div>
  );
};

export default BookingWidget;