import { 
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  collection,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase';
import type { User } from '../../types';
import toast from 'react-hot-toast';
import { logEvent } from './eventLogs';

export async function createUnclaimedBusiness(data: Omit<User, 'id' | 'email' | 'createdAt' | 'lastLogin'>) {
  try {
    const userRef = doc(collection(db, 'users'));
    const id = userRef.id;
    const timestamp = new Date().toISOString();
    const businessData: User = {
      id,
      email: data.businessInfo?.email || '',
      createdAt: timestamp,
      lastLogin: timestamp,
      updatedAt: timestamp,
      ...data,
    };

    await setDoc(userRef, businessData);

    return businessData;
  } catch (error: any) {
    console.error('Error creating unclaimed business:', error);
    throw error;
  }
}

export async function getUnclaimedBusinesses() {
  try {
    const q = query(
      collection(db, 'users'),
      where('accountType', '==', 'business'),
      where('businessInfo.claimed', '==', false),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as User[];
  } catch (error: any) {
    console.error('Error fetching unclaimed businesses:', error);
    throw error;
  }
}

export async function createUser(user: Omit<User, 'id'> & { id: string }) {
  const userRef = doc(db, 'users', user.id);
  const timestamp = new Date().toISOString();
  const userData = {
    ...user,
    createdAt: timestamp,
    updatedAt: timestamp,
    lastLogin: timestamp
  };
  
  await setDoc(userRef, {
    ...userData
  });
  return userData;
}

export async function getUser(id: string) {
  const userRef = doc(db, 'users', id);
  const userSnap = await getDoc(userRef);
  if (!userSnap.exists()) return null;
  return { id: userSnap.id, ...userSnap.data() } as User;
}

export async function updateUser(id: string, data: Partial<User>) {
  try {
    const userRef = doc(db, 'users', id);
    const timestamp = new Date().toISOString();
    
    const updateData = {
      ...data,
      updatedAt: timestamp
    };

    if (data.status) {
      await logEvent({
        type: 'system',
        action: 'UPDATE_USER_STATUS',
        userId: id,
        metadata: {
          newStatus: data.status,
          updatedAt: timestamp
        },
        status: 'success'
      });
    }

    await updateDoc(userRef, updateData);

    toast.success('User updated successfully');
  } catch (error: any) {
    console.error('Error updating user:', error);
    
    await logEvent({
      type: 'system',
      action: 'UPDATE_USER_ERROR',
      userId: id,
      metadata: { error: error.message },
      status: 'error'
    });

    if (error.code === 'permission-denied') {
      toast.error('You do not have permission to update this user');
    } else {
      toast.error('Failed to update user');
    }
    throw error;
  }
}

export async function deleteUser(id: string) {
  const userRef = doc(db, 'users', id);
  await deleteDoc(userRef);
}

export async function listUsers() {
  const q = query(collection(db, 'users'), orderBy('createdAt', 'desc'));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as User[];
}

export async function getUserByEmail(email: string) {
  const q = query(collection(db, 'users'), where('email', '==', email));
  const snapshot = await getDocs(q);
  if (snapshot.empty) return null;
  const doc = snapshot.docs[0];
  return { id: doc.id, ...doc.data() } as User;
}

export async function claimBusiness(businessId: string, claimToken: string, userId: string) {
  try {
    const businessRef = doc(db, 'users', businessId);
    const businessSnap = await getDoc(businessRef);
    
    if (!businessSnap.exists()) {
      throw new Error('Business not found');
    }
    
    const business = businessSnap.data() as User;
    
    if (!business.businessInfo?.claimToken || business.businessInfo.claimToken !== claimToken) {
      throw new Error('Invalid claim token');
    }
    
    if (business.businessInfo.claimed) {
      throw new Error('Business already claimed');
    }
    
    const timestamp = new Date().toISOString();

    await updateDoc(businessRef, {
      'businessInfo.claimed': true,
      'businessInfo.claimToken': null,
      updatedAt: timestamp
    });
    
    // Update the claiming user's account type
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      accountType: 'business',
      businessInfo: {
        ...business.businessInfo,
        claimed: true,
        claimToken: null,
      },
      updatedAt: timestamp
    });

    toast.success('Business claimed successfully!');
  } catch (error: any) {
    console.error('Error claiming business:', error);
    toast.error(error.message || 'Failed to claim business');
    throw error;
  }
}