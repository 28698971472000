import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';
import { nanoid } from 'nanoid';
import toast from 'react-hot-toast';

export async function uploadImages(files: File[], path: string): Promise<string[]> {
  const uploadPromises = files.map(async (file) => {
    try {
      const fileId = nanoid();
      const extension = file.name.split('.').pop();
      const storageRef = ref(storage, `${path}/${fileId}.${extension}`);
      
      // Add retry logic
      let attempts = 0;
      const maxAttempts = 3;
      
      while (attempts < maxAttempts) {
        try {
          const snapshot = await uploadBytes(storageRef, file);
          const downloadUrl = await getDownloadURL(snapshot.ref);
          return downloadUrl;
        } catch (error) {
          attempts++;
          if (attempts === maxAttempts) throw error;
          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  });

  return Promise.all(uploadPromises);
}

export async function uploadProfileImage(file: File, userId: string): Promise<string> {
  try {
    const fileId = nanoid();
    const extension = file.name.split('.').pop();
    const storageRef = ref(storage, `profiles/${userId}/${fileId}.${extension}`);
    
    const snapshot = await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(snapshot.ref);
    return downloadUrl;
  } catch (error) {
    console.error('Error uploading profile image:', error);
    toast.error('Failed to upload profile image');
    throw error;
  }
}