import sgMail from '@sendgrid/mail';
import { Client } from '@sendgrid/client';
import toast from 'react-hot-toast';
import { TEMPLATES, type EmailTemplateId } from './config';
import { logEvent } from '../db/eventLogs';

const SENDGRID_API_KEY = import.meta.env.VITE_SENDGRID_API_KEY;

// Initialize SendGrid client
const sgClient = new Client();

if (!SENDGRID_API_KEY || SENDGRID_API_KEY.includes('your_')) {
  console.warn('SendGrid API key not found - email functionality will be disabled');
} else {
  sgMail.setApiKey(SENDGRID_API_KEY);
  sgClient.setApiKey(SENDGRID_API_KEY);
}

interface SendEmailOptions {
  to: string;
  template: EmailTemplateId;
  data?: Record<string, any>;
  from?: {
    email: string;
    name: string;
  };
}

export async function sendEmail({ 
  to, 
  template, 
  data = {}, 
  from = {
    email: 'noreply@packmyride.com',
    name: 'PackMyRide'
  }
}: SendEmailOptions) {
  if (!SENDGRID_API_KEY) {
    console.warn('Skipping email send - SendGrid API key not configured');
    return;
  }

  try {
    // Add common template data
    const commonData = {
      year: new Date().getFullYear(),
      supportEmail: from.email,
      siteUrl: window.location.origin,
      ...data
    };

    const msg = {
      to,
      from,
      templateId: TEMPLATES[template],
      dynamicTemplateData: commonData
    };

    const [response] = await sgMail.send(msg);
    
    await logEvent({
      type: 'email',
      action: `SEND_${template}`,
      targetId: to,
      metadata: {
        template,
        data: commonData
      },
      status: 'success'
    });
    
    if (response.statusCode !== 202) {
      throw new Error(`Failed to send email: ${response.statusCode}`);
    }

    return response;
  } catch (error: any) {
    console.error('Failed to send email:', error);
    await logEvent({
      type: 'email',
      action: `SEND_${template}`,
      targetId: to,
      metadata: {
        template,
        error: error.message
      },
      status: 'error'
    });
    toast.error('Failed to send email');
    throw error;
  }
}