import React from 'react';
import { 
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import BusinessSignUp from './pages/BusinessSignUp';
import ListingDetails from './pages/ListingDetails';
import Dashboard from './pages/Dashboard';
import NewListing from './pages/NewListing';
import AdminBagManager from './pages/AdminBagManager';
import AdminUserManager from './pages/AdminUserManager';
import AdminListingManager from './pages/AdminListingManager';
import AdminConfig from './pages/AdminConfig';
import AdminCreateBusiness from './pages/AdminCreateBusiness';
import AdminEventLog from './pages/AdminEventLog';
import OwnerProfile from './pages/HostProfile';
import UserProfile from './pages/UserProfile';
import Layout from './components/Layout';
import AdminRoute from './components/AdminRoute';
import PrivateRoute from './components/PrivateRoute';
import { ToastProvider } from './components/Toast';
import ErrorBoundary from './components/ErrorBoundary';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      {/* Auth Routes */}
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/business/sign-up" element={<BusinessSignUp />} />

      {/* Main Layout Routes */}
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/listing/:id" element={<ListingDetails />} />
        <Route path="/dashboard" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path="/new-listing" element={
          <PrivateRoute>
            <NewListing />
          </PrivateRoute>
        } />
        <Route path="/owner/:id" element={<OwnerProfile />} />
        <Route path="/profile" element={
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        } />
        
        {/* Admin Routes */}
        <Route path="/admin/bags" element={
          <AdminRoute>
            <AdminBagManager />
          </AdminRoute>
        } />
        <Route path="/admin/users" element={
          <AdminRoute>
            <AdminUserManager />
          </AdminRoute>
        } />
        <Route path="/admin/listings" element={
          <AdminRoute>
            <AdminListingManager />
          </AdminRoute>
        } />
        <Route path="/admin/business/new" element={
          <AdminRoute>
            <AdminCreateBusiness />
          </AdminRoute>
        } />
        <Route path="/admin/config" element={
          <AdminRoute>
            <AdminConfig />
          </AdminRoute>
        } />
        <Route path="/admin/logs" element={
          <AdminRoute>
            <AdminEventLog />
          </AdminRoute>
        } />
      </Route>
    </Route>
  )
);

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <ToastProvider>
        <RouterProvider router={router} />
      </ToastProvider>
    </ErrorBoundary>
  );
};

export default App;