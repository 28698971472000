import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  User as FirebaseUser
} from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from './firebase';
import { sendEmail } from './email';
import { logEvent } from './db/eventLogs';
import type { User } from '../types';
import toast from 'react-hot-toast';

export const onAuthChange = (callback: (user: FirebaseUser | null) => Promise<void>) => {
  return onAuthStateChanged(auth, callback);
};

export const signUp = async (
  email: string, 
  password: string, 
  firstName?: string, 
  lastName?: string,
  additionalData?: Partial<User>
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;
    
    await logEvent({
      type: 'auth',
      action: 'SIGN_UP',
      userId: firebaseUser.uid,
      metadata: {
        email: firebaseUser.email,
        accountType: additionalData?.accountType || 'personal'
      },
      status: 'success'
    });

    if (firstName && lastName) {
      await updateProfile(firebaseUser, {
        displayName: `${firstName} ${lastName}`,
      });
    } else if (additionalData?.businessInfo?.name) {
      await updateProfile(firebaseUser, {
        displayName: additionalData.businessInfo.name,
      });
    }

    await sendEmailVerification(firebaseUser);
    
    // Send welcome email
    await sendEmail({
      to: firebaseUser.email!,
      template: 'welcome',
      data: {
        siteUrl: window.location.origin
      }
    });

    const userRef = doc(db, 'users', firebaseUser.uid);
    const userData: Omit<User, 'id'> = {
      email: firebaseUser.email!,
      firstName: firstName || '',
      lastName: lastName || '',
      accountType: additionalData?.accountType || 'personal',
      role: 'user',
      status: 'pending',
      listings: [],
      createdAt: new Date(),
      lastLogin: new Date(),
      verifiedEmail: firebaseUser.emailVerified,
      notificationPreferences: {
        email: true,
        sms: false,
      },
      ...additionalData,
    };

    await setDoc(userRef, {
      ...userData,
      updatedAt: serverTimestamp(),
    });

    toast.success('Account created successfully! Please verify your email.');
    return { id: firebaseUser.uid, ...userData };
  } catch (error: any) {
    console.error('Error in sign up:', error);
    await logEvent({
      type: 'auth',
      action: 'SIGN_UP',
      metadata: { email },
      status: 'error',
      error: error.message
    });
    let message = 'Failed to create account';
    if (error.code === 'auth/email-already-in-use') {
      message = 'This email is already registered';
    }
    toast.error(message);
    throw error;
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;
    
    await logEvent({
      type: 'auth',
      action: 'SIGN_IN',
      userId: firebaseUser.uid,
      metadata: { email: firebaseUser.email },
      status: 'success'
    });

    const userRef = doc(db, 'users', firebaseUser.uid);
    const userSnap = await getDoc(userRef);
    
    if (!userSnap.exists()) {
      throw new Error('User document not found');
    }

    await setDoc(userRef, {
      lastLogin: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }, { merge: true });

    toast.success('Welcome back!');
    return { id: userSnap.id, ...userSnap.data() } as User;
  } catch (error: any) {
    console.error('Error in sign in:', error);
    await logEvent({
      type: 'auth',
      action: 'SIGN_IN',
      metadata: { email },
      status: 'error',
      error: error.message
    });
    let message = 'Failed to sign in';
    if (error.code === 'auth/invalid-credential') {
      message = 'Invalid email or password';
    } else if (error.code === 'auth/user-not-found') {
      message = 'No account found with this email';
    }
    toast.error(message);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    toast.success('Signed out successfully');
  } catch (error) {
    console.error('Error in sign out:', error);
    toast.error('Failed to sign out');
    throw error;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success('Password reset email sent!');
  } catch (error) {
    console.error('Error sending reset email:', error);
    toast.error('Failed to send reset email');
    throw error;
  }
};

export const resendVerificationEmail = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error('No user is currently signed in');
  }
  try {
    await sendEmailVerification(currentUser);
    await sendEmail({
      to: currentUser.email!,
      template: 'verifyEmail',
      data: {
        verificationUrl: `${window.location.origin}/verify-email?code=${currentUser.uid}`
      }
    });
    toast.success('Verification email sent!');
  } catch (error) {
    console.error('Error sending verification email:', error);
    toast.error('Failed to send verification email');
    throw error;
  }
};