import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X } from 'lucide-react';
import UserAvatar from './UserAvatar';

interface ProfileImageUploadProps {
  currentImage?: string;
  firstName?: string;
  lastName?: string;
  onChange: (file: File) => void;
  maxSize?: number;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  currentImage,
  firstName,
  lastName,
  onChange,
  maxSize = 5 * 1024 * 1024, // 5MB default
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles[0]);
    }
  }, [onChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxSize,
    maxFiles: 1,
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-6">
        <UserAvatar
          size="lg"
          profileImage={currentImage}
          firstName={firstName}
          lastName={lastName}
        />
        
        <div
          {...getRootProps()}
          className={`flex-1 p-4 border-2 border-dashed rounded-lg cursor-pointer transition-colors ${
            isDragActive 
              ? 'border-primary-500 bg-primary-50'
              : 'border-gray-300 hover:border-primary-500'
          }`}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center text-sm text-gray-600">
            <Upload className="w-6 h-6 mb-2 text-gray-400" />
            {isDragActive ? (
              <p>Drop the image here</p>
            ) : (
              <>
                <p>Drag & drop a profile photo or click to select</p>
                <p className="text-xs text-gray-500 mt-1">
                  JPEG, PNG or WebP (max. {maxSize / (1024 * 1024)}MB)
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageUpload;