import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  orderBy,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase';
import { Bag } from '../../types';
import toast from 'react-hot-toast';

// Validation functions
const validateBag = (bagData: Omit<Bag, 'id' | 'createdAt' | 'updatedAt'>) => {
  if (!bagData.wheelbase?.max || 
      bagData.wheelbase.max < 50 || 
      bagData.wheelbase.max > 200) {
    throw new Error('Wheelbase must be between 50cm and 200cm');
  }
  return true;
};

export const createBag = async (bagData: Omit<Bag, 'id' | 'createdAt' | 'updatedAt'>) => {
  try {
    validateBag(bagData);
    
    const docRef = await addDoc(collection(db, 'bags'), {
      ...bagData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    toast.success('Bag created successfully');
    return { id: docRef.id, ...bagData };
  } catch (error: any) {
    console.error('Error creating bag:', error);
    if (error.code === 'permission-denied') {
      toast.error('You do not have permission to create bags');
    } else {
      toast.error(error.message || 'Failed to create bag');
    }
    throw error;
  }
};

export const updateBag = async (id: string, bagData: Partial<Bag>) => {
  try {
    if (bagData.wheelbase) {
      validateBag({ ...bagData, wheelbase: bagData.wheelbase } as any);
    }
    
    const bagRef = doc(db, 'bags', id);
    await updateDoc(bagRef, {
      ...bagData,
      updatedAt: serverTimestamp()
    });
    toast.success('Bag updated successfully');
  } catch (error: any) {
    console.error('Error updating bag:', error);
    toast.error(error.message || 'Failed to update bag');
    throw error;
  }
};

export const deleteBag = async (id: string) => {
  try {
    const bagRef = doc(db, 'bags', id);
    await deleteDoc(bagRef);
    toast.success('Bag deleted successfully');
  } catch (error: any) {
    console.error('Error deleting bag:', error);
    toast.error(error.message || 'Failed to delete bag');
    throw error;
  }
};

export const listBags = async () => {
  try {
    const q = query(collection(db, 'bags'), orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Bag[];
  } catch (error: any) {
    console.error('Error listing bags:', error);
    if (error.code === 'permission-denied') {
      toast.error('Unable to load bags. Please try again later.');
    } else {
      toast.error(error.message || 'Failed to load bags');
    }
    throw error;
  }
};