import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import EmailVerificationBanner from './EmailVerificationBanner';
import Toast from './Toast';
import OfflineIndicator from './OfflineIndicator';

const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <EmailVerificationBanner />
      <main className="min-h-screen bg-gray-50">
        <Outlet />
      </main>
      <Footer />
      <Toast />
      <OfflineIndicator />
    </>
  );
};

export default Layout;