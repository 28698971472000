import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  collection,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase';
import type { Brand } from '../../types';
import toast from 'react-hot-toast';

export async function createBrand(brand: Omit<Brand, 'id' | 'createdAt' | 'updatedAt'>) {
  try {
    const brandRef = doc(collection(db, 'brands'));
    const id = brandRef.id;
    const newBrand: Brand = {
      ...brand,
      id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    
    await setDoc(brandRef, {
      ...newBrand,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    toast.success('Brand created successfully!');
    return newBrand;
  } catch (error: any) {
    console.error('Error creating brand:', error);
    toast.error(error.message || 'Failed to create brand');
    throw error;
  }
}

export async function getBrand(id: string) {
  try {
    const brandRef = doc(db, 'brands', id);
    const brandSnap = await getDoc(brandRef);
    if (!brandSnap.exists()) return null;
    return { id: brandSnap.id, ...brandSnap.data() } as Brand;
  } catch (error: any) {
    console.error('Error getting brand:', error);
    throw error;
  }
}

export async function updateBrand(id: string, data: Partial<Omit<Brand, 'id' | 'createdAt' | 'updatedAt'>>) {
  try {
    const brandRef = doc(db, 'brands', id);
    await updateDoc(brandRef, {
      ...data,
      updatedAt: serverTimestamp(),
    });
    toast.success('Brand updated successfully!');
  } catch (error: any) {
    console.error('Error updating brand:', error);
    toast.error(error.message || 'Failed to update brand');
    throw error;
  }
}

export async function deleteBrand(id: string) {
  try {
    const brandRef = doc(db, 'brands', id);
    await deleteDoc(brandRef);
    toast.success('Brand deleted successfully!');
  } catch (error: any) {
    console.error('Error deleting brand:', error);
    toast.error(error.message || 'Failed to delete brand');
    throw error;
  }
}

export async function listBrands() {
  try {
    const q = query(collection(db, 'brands'), orderBy('name', 'asc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Brand[];
  } catch (error: any) {
    console.error('Error listing brands:', error);
    throw error;
  }
}

export async function getBrandByName(name: string) {
  try {
    const q = query(collection(db, 'brands'), where('name', '==', name));
    const snapshot = await getDocs(q);
    if (snapshot.empty) return null;
    const doc = snapshot.docs[0];
    return { id: doc.id, ...doc.data() } as Brand;
  } catch (error: any) {
    console.error('Error getting brand by name:', error);
    throw error;
  }
}