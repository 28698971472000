import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './context/AuthContext';
import { BrandProvider } from './context/BrandContext';
import { UserProvider } from './context/UserContext';
import { LocationProvider } from './context/LocationContext';
import App from './App';
import './index.css';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

createRoot(rootElement).render(
  <StrictMode>
    <AuthProvider>
      <UserProvider>
        <BrandProvider>
          <LocationProvider>
            <App />
          </LocationProvider>
        </BrandProvider>
      </UserProvider>
    </AuthProvider>
  </StrictMode>
);