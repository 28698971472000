import React, { useEffect, useRef } from 'react';
import { UseFormSetValue, UseFormRegister } from 'react-hook-form';
import { MapPin, Loader2 } from 'lucide-react';
import { useGoogleMapsScript } from '../utils/googleMaps';

interface AddressAutocompleteProps {
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  defaultValue?: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  setValue,
  register,
  defaultValue = '',
}) => {
  const isScriptLoaded = useGoogleMapsScript();
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isScriptLoaded || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: ['au', 'nz', 'gb', 'us', 'ca', 'ie'] },
      fields: ['address_components', 'geometry'],
    };

    autocompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autocompleteRef.current.addListener('place_changed', () => {
      const place = autocompleteRef.current?.getPlace();
      if (!place?.address_components) return;

      const addressComponents = place.address_components;
      const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
      const city = addressComponents.find(c => c.types.includes('locality'))?.long_name || '';
      const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.short_name || '';
      const postalCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '';
      const country = addressComponents.find(c => c.types.includes('country'))?.short_name || '';
      const unit = addressComponents.find(c => c.types.includes('subpremise'))?.long_name || '';

      setValue('address.street', `${streetNumber} ${route}`.trim());
      setValue('address.unit', unit);
      setValue('address.city', city);
      setValue('address.state', state);
      setValue('address.postalCode', postalCode);
      setValue('address.country', country);
      if (place.geometry?.location) {
        setValue('address.coordinates', {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    });

    return () => {
      if (autocompleteRef.current) {
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, [isScriptLoaded, setValue]);

  if (!isScriptLoaded) {
    return (
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />
        </div>
        <input
          disabled
          className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm bg-gray-50"
          placeholder="Loading address search..."
        />
      </div>
    );
  }

  return (
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MapPin className="h-5 w-5 text-gray-400" />
      </div>
      <input
        {...register('address.street')}
        ref={(e) => {
          register('address.street').ref(e);
          inputRef.current = e;
        }}
        defaultValue={defaultValue}
        className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
        placeholder="Start typing your address..."
      />
    </div>
  );
};

export default AddressAutocomplete;