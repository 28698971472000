import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Package, AlertCircle, Building2, Globe, FileText } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import AddressForm from '../components/AddressForm';
import toast from 'react-hot-toast';

const businessSignUpSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  confirmPassword: z.string(),
  business: z.object({
    name: z.string().min(2, 'Business name must be at least 2 characters'),
    type: z.enum(['shop', 'rental', 'manufacturer', 'other'], {
      required_error: 'Please select a business type',
    }),
    website: z.string().url('Invalid website URL').optional(),
    taxId: z.string().optional(),
  }),
  address: z.object({
    street: z.string().min(5, 'Street address is required'),
    unit: z.string().optional(),
    city: z.string().min(2, 'City is required'),
    state: z.string().min(2, 'State is required'),
    postalCode: z.string().min(3, 'Postal code is required'),
    country: z.string().min(2, 'Country is required'),
    coordinates: z.object({
      lat: z.number(),
      lng: z.number(),
    }),
    privacy: z.enum(['public', 'city_only']),
  }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

type BusinessSignUpInputs = z.infer<typeof businessSignUpSchema>;

const BusinessSignUp: React.FC = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<BusinessSignUpInputs>({
    resolver: zodResolver(businessSignUpSchema),
  });

  const onSubmit = async (data: BusinessSignUpInputs) => {
    try {
      setAuthError(null);
      await signUp(data.email, data.password, undefined, undefined, {
        accountType: 'business',
        businessInfo: data.business,
        address: data.address,
      });
      navigate('/');
    } catch (error) {
      if (error instanceof Error) {
        setAuthError(error.message);
      } else {
        setAuthError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="py-8">
        <div className="container mx-auto px-4">
          <Link to="/" className="flex items-center justify-center space-x-2">
            <Package className="w-10 h-10 text-primary-500" />
            <span className="text-2xl font-bold text-gray-900">packmyride</span>
          </Link>
        </div>
      </header>

      <main className="flex-1 flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-2xl">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-3">Create Business Account</h1>
            <p className="text-gray-600">
              Already have an account?{' '}
              <Link to="/sign-in" className="text-primary-500 hover:text-primary-600 font-medium">
                Sign in
              </Link>
            </p>
          </div>

          {authError && (
            <div className="mb-6 bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
              <span>{authError}</span>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            {/* Business Information */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center space-x-3 mb-6">
                <Building2 className="w-6 h-6 text-primary-500" />
                <div>
                  <h2 className="text-lg font-semibold text-gray-900">Business Information</h2>
                  <p className="text-sm text-gray-500">Basic details about your business</p>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="form-label">Business Name</label>
                  <input
                    {...register('business.name')}
                    className="form-input"
                    placeholder="Your business name"
                  />
                  {errors.business?.name && (
                    <p className="form-error">{errors.business.name.message}</p>
                  )}
                </div>

                <div>
                  <label className="form-label">Business Type</label>
                  <select {...register('business.type')} className="form-select">
                    <option value="">Select business type</option>
                    <option value="shop">Bike Shop</option>
                    <option value="rental">Rental Service</option>
                    <option value="manufacturer">Manufacturer</option>
                    <option value="other">Other</option>
                  </select>
                  {errors.business?.type && (
                    <p className="form-error">{errors.business.type.message}</p>
                  )}
                </div>

                <div>
                  <label className="form-label">Website (Optional)</label>
                  <div className="relative">
                    <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      {...register('business.website')}
                      type="url"
                      className="form-input pl-10"
                      placeholder="https://example.com"
                    />
                  </div>
                  {errors.business?.website && (
                    <p className="form-error">{errors.business.website.message}</p>
                  )}
                </div>

                <div>
                  <label className="form-label">Tax ID (Optional)</label>
                  <div className="relative">
                    <FileText className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      {...register('business.taxId')}
                      className="form-input pl-10"
                      placeholder="Tax ID or VAT number"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Account Information */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-6">Account Information</h2>

              <div className="space-y-6">
                <div>
                  <label className="form-label">Email</label>
                  <input
                    {...register('email')}
                    type="email"
                    className="form-input"
                    placeholder="business@example.com"
                  />
                  {errors.email && (
                    <p className="form-error">{errors.email.message}</p>
                  )}
                </div>

                <div>
                  <label className="form-label">Password</label>
                  <input
                    {...register('password')}
                    type="password"
                    className="form-input"
                    placeholder="••••••••"
                  />
                  {errors.password && (
                    <p className="form-error">{errors.password.message}</p>
                  )}
                </div>

                <div>
                  <label className="form-label">Confirm Password</label>
                  <input
                    {...register('confirmPassword')}
                    type="password"
                    className="form-input"
                    placeholder="••••••••"
                  />
                  {errors.confirmPassword && (
                    <p className="form-error">{errors.confirmPassword.message}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Business Address */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-6">Business Address</h2>
              <AddressForm
                register={register}
                setValue={setValue}
                errors={errors}
              />
            </div>

            <div className="flex flex-col space-y-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-primary"
              >
                {isSubmitting ? 'Creating account...' : 'Create Business Account'}
              </button>

              <p className="text-sm text-gray-500 text-center">
                By signing up, you agree to our{' '}
                <Link to="/terms" className="text-primary-500 hover:text-primary-600">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/privacy" className="text-primary-500 hover:text-primary-600">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </form>
        </div>
      </main>

      <footer className="py-8">
        <div className="container mx-auto px-4 text-center text-sm text-gray-600">
          © {new Date().getFullYear()} packmyride. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default BusinessSignUp;