import React, { useState, useEffect } from 'react';
import { Activity, Filter, Search, AlertCircle, CheckCircle, Mail, User, Calendar } from 'lucide-react';
import { getEventLogs } from '../lib/db/eventLogs';
import type { EventLog } from '../types';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

const AdminEventLog: React.FC = () => {
  const [logs, setLogs] = useState<EventLog[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<EventLog['type'] | 'all'>('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const loadLogs = async () => {
      try {
        setIsLoading(true);
        const eventLogs = await getEventLogs({
          type: filter === 'all' ? undefined : filter,
        });
        setLogs(eventLogs);
      } catch (error) {
        console.error('Failed to load event logs:', error);
        toast.error('Failed to load event logs');
      } finally {
        setIsLoading(false);
      }
    };

    loadLogs();
  }, [filter]);

  const getEventIcon = (type: EventLog['type']) => {
    switch (type) {
      case 'auth':
        return <User className="w-5 h-5" />;
      case 'booking':
        return <Calendar className="w-5 h-5" />;
      case 'email':
        return <Mail className="w-5 h-5" />;
      default:
        return <Activity className="w-5 h-5" />;
    }
  };

  const filteredLogs = logs.filter(log => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      log.action.toLowerCase().includes(query) ||
      log.userId?.toLowerCase().includes(query) ||
      log.targetId?.toLowerCase().includes(query) ||
      JSON.stringify(log.metadata).toLowerCase().includes(query)
    );
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Activity className="w-8 h-8 text-primary-500" />
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Event Log</h2>
            <p className="text-gray-500">Monitor system events and activities</p>
          </div>
        </div>

        <div className="mb-6 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search events..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
          
          <div className="flex items-center space-x-2">
            <Filter className="w-5 h-5 text-gray-400" />
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as EventLog['type'] | 'all')}
              className="border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            >
              <option value="all">All Events</option>
              <option value="auth">Authentication</option>
              <option value="booking">Bookings</option>
              <option value="email">Emails</option>
              <option value="system">System</option>
            </select>
          </div>
        </div>

        {isLoading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500 mx-auto"></div>
            <p className="mt-4 text-gray-500">Loading events...</p>
          </div>
        ) : filteredLogs.length > 0 ? (
          <div className="space-y-4">
            {filteredLogs.map((log) => (
              <div
                key={log.id}
                className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start space-x-3">
                    <div className={`p-2 rounded-lg ${
                      log.status === 'success' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                    }`}>
                      {getEventIcon(log.type)}
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <h3 className="font-medium text-gray-900">{log.action}</h3>
                        {log.status === 'success' ? (
                          <CheckCircle className="w-4 h-4 text-green-500" />
                        ) : (
                          <AlertCircle className="w-4 h-4 text-red-500" />
                        )}
                      </div>
                      {log.userId && (
                        <p className="text-sm text-gray-500">User: {log.userId}</p>
                      )}
                      {log.targetId && (
                        <p className="text-sm text-gray-500">Target: {log.targetId}</p>
                      )}
                      {log.error && (
                        <p className="text-sm text-red-600 mt-1">{log.error}</p>
                      )}
                      {Object.keys(log.metadata).length > 0 && (
                        <pre className="mt-2 text-xs bg-gray-100 p-2 rounded overflow-x-auto">
                          {JSON.stringify(log.metadata, null, 2)}
                        </pre>
                      )}
                    </div>
                  </div>
                  <span className="text-sm text-gray-500">
                    {format(new Date(log.timestamp), 'PPp')}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <Activity className="w-12 h-12 text-gray-400 mx-auto" />
            <h3 className="mt-2 text-lg font-medium text-gray-900">No events found</h3>
            <p className="mt-1 text-gray-500">
              {searchQuery
                ? 'Try adjusting your search query'
                : 'Events will appear here as they occur'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminEventLog;