import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { 
  Mail, Bell, Globe, Save, Eye, EyeOff, Loader2,
  Share2, Image as ImageIcon, Upload, X
} from 'lucide-react';

const configSchema = z.object({
  email: z.object({
    provider: z.enum(['smtp', 'sendgrid', 'mailgun']),
    apiKey: z.string().min(1, 'API key is required'),
    fromEmail: z.string().email('Invalid email address'),
    fromName: z.string().min(1, 'From name is required'),
  }),
  sms: z.object({
    provider: z.enum(['twilio', 'messagebird']),
    apiKey: z.string().min(1, 'API key is required'),
    fromNumber: z.string().min(1, 'From number is required'),
  }),
  general: z.object({
    siteName: z.string().min(1, 'Site name is required'),
    siteUrl: z.string().url('Invalid URL'),
    supportEmail: z.string().email('Invalid email address'),
  }),
  openGraph: z.object({
    title: z.string().min(1, 'Title is required'),
    description: z.string().min(1, 'Description is required').max(200, 'Description must be less than 200 characters'),
    image: z.string().url('Must be a valid URL'),
    twitterHandle: z.string().optional(),
    facebookAppId: z.string().optional(),
    defaultImageAlt: z.string().min(1, 'Default image alt text is required'),
    locale: z.string().min(2, 'Locale is required'),
    type: z.enum(['website', 'article', 'product']),
  }),
});

type ConfigInputs = z.infer<typeof configSchema>;

const ConfigurationManager: React.FC = () => {
  const [showSecrets, setShowSecrets] = useState<Record<string, boolean>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ConfigInputs>({
    resolver: zodResolver(configSchema),
    defaultValues: {
      email: {
        provider: 'smtp',
        apiKey: '',
        fromEmail: 'noreply@example.com',
        fromName: 'BikeBag Rentals',
      },
      sms: {
        provider: 'twilio',
        apiKey: '',
        fromNumber: '',
      },
      general: {
        siteName: 'BikeBag Rentals',
        siteUrl: 'https://bikebag.example.com',
        supportEmail: 'support@example.com',
      },
      openGraph: {
        title: 'BikeBag Rentals - Bike Transport Made Easy',
        description: 'Find and rent bike bags for safe transport of your bicycle. Connect with bike shops and fellow cyclists worldwide.',
        image: 'https://images.unsplash.com/photo-1582560475093-ba66accbc424?auto=format&fit=crop&q=80&w=1200',
        twitterHandle: '@bikebag',
        facebookAppId: '',
        defaultImageAlt: 'BikeBag Rentals - Bike Transport Solutions',
        locale: 'en_US',
        type: 'website',
      },
    },
  });

  const toggleSecretVisibility = (field: string) => {
    setShowSecrets(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const onSubmit = async (data: ConfigInputs) => {
    try {
      setIsSaving(true);
      // TODO: Implement API call to save configuration
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
      console.log('Saving configuration:', data);
    } catch (error) {
      console.error('Failed to save configuration:', error);
      alert('Failed to save configuration');
    } finally {
      setIsSaving(false);
    }
  };

  const watchedImage = watch('openGraph.image');
  React.useEffect(() => {
    setPreviewImage(watchedImage);
  }, [watchedImage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      {/* Previous sections remain unchanged */}
      
      {/* OpenGraph Configuration Section */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Share2 className="w-6 h-6 text-primary-500" />
          <div>
            <h3 className="text-lg font-semibold text-gray-900">Social Media & OpenGraph</h3>
            <p className="text-sm text-gray-500">Configure how your site appears when shared</p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Default Title
            </label>
            <input
              {...register('openGraph.title')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder="Your site's default title for social sharing"
            />
            {errors.openGraph?.title && (
              <p className="mt-1 text-sm text-red-600">{errors.openGraph.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Default Description
            </label>
            <textarea
              {...register('openGraph.description')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder="Brief description of your site (max 200 characters)"
            />
            {errors.openGraph?.description && (
              <p className="mt-1 text-sm text-red-600">{errors.openGraph.description.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Default Image URL
            </label>
            <div className="mt-1">
              <input
                {...register('openGraph.image')}
                type="url"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="https://example.com/image.jpg"
              />
              {errors.openGraph?.image && (
                <p className="mt-1 text-sm text-red-600">{errors.openGraph.image.message}</p>
              )}
              {previewImage && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500 mb-2">Preview:</p>
                  <div className="relative w-full max-w-md">
                    <img
                      src={previewImage}
                      alt="OpenGraph preview"
                      className="rounded-lg shadow-sm"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setValue('openGraph.image', '');
                        setPreviewImage(null);
                      }}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Default Image Alt Text
            </label>
            <input
              {...register('openGraph.defaultImageAlt')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder="Descriptive text for the default image"
            />
            {errors.openGraph?.defaultImageAlt && (
              <p className="mt-1 text-sm text-red-600">{errors.openGraph.defaultImageAlt.message}</p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Twitter Handle
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500">
                  @
                </span>
                <input
                  {...register('openGraph.twitterHandle')}
                  className="block w-full pl-8 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  placeholder="yourtwitterhandle"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Facebook App ID
              </label>
              <input
                {...register('openGraph.facebookAppId')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="Optional Facebook App ID"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Default Locale
              </label>
              <select
                {...register('openGraph.locale')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              >
                <option value="en_US">English (US)</option>
                <option value="en_GB">English (UK)</option>
                <option value="en_AU">English (Australia)</option>
                <option value="en_CA">English (Canada)</option>
                <option value="en_NZ">English (New Zealand)</option>
                <option value="en_IE">English (Ireland)</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Default Content Type
              </label>
              <select
                {...register('openGraph.type')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              >
                <option value="website">Website</option>
                <option value="article">Article</option>
                <option value="product">Product</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isSaving}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSaving ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Saving Changes...
            </>
          ) : (
            <>
              <Save className="w-4 h-4 mr-2" />
              Save Changes
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default ConfigurationManager;