import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { MapPin, Building2 } from 'lucide-react';
import AddressAutocomplete from './AddressAutocomplete';

interface AddressFormProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  errors: any;
  defaultAddress?: {
    street?: string;
    unit?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
}

const AddressForm: React.FC<AddressFormProps> = ({
  register,
  setValue,
  errors,
  defaultAddress,
}) => {
  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Street Address
        </label>
        <div className="mt-1">
          <AddressAutocomplete
            setValue={setValue}
            defaultValue={defaultAddress?.street}
            register={register}
          />
          {errors.address?.street && (
            <p className="mt-1 text-sm text-red-600">{errors.address.street.message}</p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Apartment/Unit (Optional)
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Building2 className="h-5 w-5 text-gray-400" />
          </div>
          <input
            {...register('address.unit')}
            className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            placeholder="Apt, Suite, Unit, etc."
            defaultValue={defaultAddress?.unit}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            City
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <input
              {...register('address.city')}
              className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              defaultValue={defaultAddress?.city}
            />
          </div>
          {errors.address?.city && (
            <p className="mt-1 text-sm text-red-600">{errors.address.city.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            State/Province
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <input
              {...register('address.state')}
              className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              defaultValue={defaultAddress?.state}
            />
          </div>
          {errors.address?.state && (
            <p className="mt-1 text-sm text-red-600">{errors.address.state.message}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Postal Code
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <input
              {...register('address.postalCode')}
              className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              defaultValue={defaultAddress?.postalCode}
            />
          </div>
          {errors.address?.postalCode && (
            <p className="mt-1 text-sm text-red-600">{errors.address.postalCode.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Country
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <select
              {...register('address.country')}
              className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              defaultValue={defaultAddress?.country || 'AU'}
            >
              <option value="AU">Australia</option>
              <option value="NZ">New Zealand</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="IE">Ireland</option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Address Privacy
        </label>
        <div className="space-y-3">
          <label className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                type="radio"
                {...register('address.privacy')}
                value="public"
                className="h-4 w-4 text-primary-500 border-gray-300 focus:ring-primary-500"
              />
            </div>
            <div className="ml-3">
              <span className="text-sm font-medium text-gray-700">Show full address</span>
              <p className="text-sm text-gray-500">Display complete address details to users</p>
            </div>
          </label>
          <label className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                type="radio"
                {...register('address.privacy')}
                value="city_only"
                className="h-4 w-4 text-primary-500 border-gray-300 focus:ring-primary-500"
              />
            </div>
            <div className="ml-3">
              <span className="text-sm font-medium text-gray-700">Show only city and state</span>
              <p className="text-sm text-gray-500">Hide street address for privacy</p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;