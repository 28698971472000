import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { X, Upload } from 'lucide-react';

interface ImageUploadProps {
  images: File[];
  onChange: (files: File[]) => void;
  maxImages?: number;
  maxSize?: number;
  thumbnailIndex: number;
  onThumbnailChange: (index: number) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  images,
  onChange,
  maxImages = 5,
  maxSize = 5 * 1024 * 1024,
  thumbnailIndex,
  onThumbnailChange,
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const remainingSlots = maxImages - images.length;
    const newFiles = acceptedFiles.slice(0, remainingSlots);
    onChange([...images, ...newFiles]);
    
    // If this is the first image being uploaded, make it the thumbnail
    if (images.length === 0 && newFiles.length > 0) {
      onThumbnailChange(0);
    }
  }, [images, maxImages, onChange, onThumbnailChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxSize,
    disabled: images.length >= maxImages,
  });

  const removeImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    onChange(newImages);
    
    // If we're removing the thumbnail, set the first remaining image as thumbnail
    if (index === thumbnailIndex) {
      onThumbnailChange(newImages.length > 0 ? 0 : -1);
    } else if (index < thumbnailIndex) {
      // If we're removing an image before the thumbnail, adjust the thumbnail index
      onThumbnailChange(thumbnailIndex - 1);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {images.map((file, index) => (
          <div
            key={index}
            className={`relative aspect-square rounded-lg overflow-hidden bg-gray-100 group ${
              thumbnailIndex === index ? 'ring-4 ring-primary-500 ring-offset-2' : ''
            }`}
          >
            <img
              src={URL.createObjectURL(file)}
              alt={`Preview ${index + 1}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
              <button
                onClick={() => onThumbnailChange(index)}
                className={`px-3 py-1.5 rounded-lg transition-colors ${
                  thumbnailIndex === index
                    ? 'bg-primary-500 text-white'
                    : 'bg-white/80 hover:bg-primary-500 hover:text-white text-gray-700'
                }`}
              >
                {thumbnailIndex === index ? 'Main Image' : 'Set as Main'}
              </button>
              <button
                onClick={() => removeImage(index)}
                className="absolute top-2 right-2 p-1.5 bg-white/80 hover:bg-red-500 hover:text-white text-gray-700 rounded-lg transition-colors"
                title="Remove image"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
            {thumbnailIndex === index && (
              <div className="absolute top-2 left-2 px-2 py-1 bg-primary-500 text-white text-xs rounded-lg">
                Main Image
              </div>
            )}
          </div>
        ))}

        {images.length < maxImages && (
          <div
            {...getRootProps()}
            className={`aspect-square rounded-lg border-2 border-dashed ${
              isDragActive ? 'border-primary-500 bg-primary-500/10' : 'border-gray-300 hover:border-primary-500'
            } transition-colors duration-200 flex flex-col items-center justify-center cursor-pointer`}
          >
            <input {...getInputProps()} />
            <Upload className="w-8 h-8 text-gray-400 mb-2" />
            <p className="text-sm text-gray-500 text-center px-2">
              {isDragActive ? (
                "Drop the image here"
              ) : (
                <>
                  Drag & drop or click to upload
                  <br />
                  <span className="text-xs">
                    ({images.length}/{maxImages} images)
                  </span>
                </>
              )}
            </p>
          </div>
        )}
      </div>

      <div className="text-sm text-gray-500">
        <p>Supported formats: JPEG, PNG, WebP</p>
        <p>Maximum size: {maxSize / (1024 * 1024)}MB per image</p>
        <p>First image will be used as the main thumbnail if none is selected</p>
      </div>
    </div>
  );
};

export default ImageUpload;