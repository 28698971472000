import React, { useState, useEffect } from 'react';
import { Search, Filter } from 'lucide-react';
import ListingCard from '../components/ListingCard';
import SearchFilters from '../components/SearchFilters';
import { listListings } from '../lib/db/listings';
import type { Listing } from '../types';
import toast from 'react-hot-toast';

function Home() {
  const [showFilters, setShowFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [listings, setListings] = useState<Listing[]>([]);
  const [filteredListings, setFilteredListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    filterListings({ query: searchQuery });
  };

  const handleAdvancedSearch = (filters: any) => {
    filterListings({ ...filters, query: searchQuery });
  };

  const filterListings = (filters: any) => {
    let filtered = [...listings];

    // Location filter
    if (filters.location) {
      const location = filters.location.toLowerCase();
      filtered = filtered.filter(listing =>
        listing.location.toLowerCase().includes(location)
      );
    }

    // Bike type filter
    if (filters.bikeType) {
      filtered = filtered.filter(listing =>
        listing.bagSpecs.bikeCompatibility?.[filters.bikeType]
      );
    }

    // Brand filter
    if (filters.brand) {
      filtered = filtered.filter(listing =>
        listing.bagSpecs.brand?.toLowerCase() === filters.brand.toLowerCase()
      );
    }

    // Price filter
    if (filters.maxDailyRate) {
      filtered = filtered.filter(listing =>
        listing.pricingSchedule.dailyRate <= filters.maxDailyRate
      );
    }

    // Text search (apply last to filter across all fields)
    if (filters.query) {
      const query = filters.query.toLowerCase();
      filtered = filtered.filter(listing =>
        listing.bagSpecs.model.toLowerCase().includes(query) ||
        listing.location.toLowerCase().includes(query) ||
        listing.description.toLowerCase().includes(query) ||
        listing.bagSpecs.brand?.toLowerCase().includes(query)
      );
    }

    setFilteredListings(filtered);
  };

  useEffect(() => {
    const loadListings = async () => {
      try {
        const loadedListings = await listListings();
        setListings(loadedListings);
        setFilteredListings(loadedListings);
      } catch (error) {
        console.error('Error loading listings:', error);
        toast.error('Failed to load listings');
      } finally {
        setIsLoading(false);
      }
    };

    loadListings();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        {/* Search Section */}
        <div className="max-w-2xl mx-auto mb-6">
          <form onSubmit={handleSearch} className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search bike bags..."
              className="w-full h-14 pl-14 pr-6 text-lg rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-500/20 transition-all duration-200"
            />
            <Search className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <button
              type="button"
              onClick={() => setShowFilters(!showFilters)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              <Filter className="w-5 h-5" />
            </button>
          </form>
          <div className="mt-4">
            <SearchFilters 
              onSearch={handleAdvancedSearch}
              isOpen={showFilters}
            />
          </div>
        </div>

        {/* Listings Section */}
        <div>
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-bold text-gray-900">
              {searchQuery ? 'Search Results' : 'Available Bike Bags'}
            </h2>
          </div>

          {isLoading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500 mx-auto"></div>
              <p className="mt-4 text-gray-500">Loading listings...</p>
            </div>
          ) : filteredListings.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {filteredListings.map(listing => (
                <ListingCard key={listing.id} listing={listing} view="grid" />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-600 text-lg">
                {searchQuery ? (
                  <>No listings found for "{searchQuery}". Try a different search term.</>
                ) : (
                  'No listings available yet.'
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;