import React from 'react';
import { ShoppingBag } from 'lucide-react';
import ListingManagement from '../components/ListingManagement';

function AdminListingManager() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <ShoppingBag className="w-8 h-8 text-primary-500" />
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Listing Management</h2>
            <p className="text-gray-500">Manage bike bag listings and availability</p>
          </div>
        </div>
        <ListingManagement />
      </div>
    </div>
  );
}

export default AdminListingManager;