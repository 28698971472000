import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { 
  ArrowLeft, Star, MapPin, MessageCircle, ThumbsUp, 
  Calendar, Shield, Bike, Scale, Ruler, Lock, Loader2, Briefcase
} from 'lucide-react';
import ImageGallery from '../components/ImageGallery';
import AuthModal from '../components/AuthModal';
import OwnerHoverCard from '../components/OwnerHoverCard';
import UserAvatar from '../components/UserAvatar';
import BookingWidget from '../components/BookingWidget';
import { getListing } from '../lib/db/listings';
import type { Listing } from '../types';
import { usePrice } from '../utils/currency';
import ShareListing from '../components/ShareListing';
import { useAuth } from '../context/AuthContext';
import toast from 'react-hot-toast';

const ListingHeader: React.FC<{ listing: Listing }> = ({ listing }) => {
  return (
    <div className="space-y-4">
      <h1 className="text-3xl font-bold text-gray-900">
        {listing.bagSpecs.model}
      </h1>

      <div className="flex items-center justify-left space-x-3 text-gray-600">
        <div className="flex items-center">
          <MapPin className="w-5 h-5 mr-1" />
          <span>{listing.location}</span>
        </div>
        <span className="text-gray-400">•</span>
        <div className="flex items-center">
          <Star className="w-5 h-5 text-yellow-500 mr-1" />
          <span>{listing.rating}</span>
          <span className="ml-1">({listing.reviews} reviews)</span>
        </div>
        <span className="text-gray-400">•</span>
        <div className="flex items-center">
          <Briefcase className="w-5 h-5 mr-1" />
          <span>{listing.trips || 0} trips</span>
        </div>
      </div>

      <div className="flex items-center space-x-3">
        <OwnerHoverCard 
          owner={{
            ...listing.owner,
            rating: listing.rating,
            reviews: listing.reviews,
            responseTime: "< 1 hour",
            responseRate: 98,
            verifiedIdentity: true,
            joinedDate: new Date(listing.owner.createdAt),
          }}
        >
          <div className="cursor-pointer">
            <UserAvatar
              size="md"
              profileImage={listing.owner.profileImage}
              firstName={listing.owner.firstName}
              lastName={listing.owner.lastName}
            />
          </div>
        </OwnerHoverCard>
        <div>
          <h2 className="text-base font-medium text-gray-900">
            {listing.owner.firstName} {listing.owner.lastName}
          </h2>
        </div>
      </div>
    </div>
  );
};

const ListingDetails: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { format: formatPrice } = usePrice();
  const [listing, setListing] = useState<Listing | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    const loadListing = async () => {
      if (!id) return;
      try {
        const loadedListing = await getListing(id);
        setListing(loadedListing);
      } catch (error) {
        console.error('Error loading listing:', error);
        toast.error('Failed to load listing');
      } finally {
        setIsLoading(false);
      }
    };

    loadListing();
  }, [id]);

  useEffect(() => {
    if (location.state?.newListing) {
      toast.success('Your listing is now live! 🎉', {
        duration: 5000,
        position: 'top-center',
      });
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleBookingSubmit = async (startDate: Date, endDate: Date) => {
    if (!user) {
      setShowAuthModal(true);
      throw new Error('User not authenticated');
    }

    await new Promise(resolve => setTimeout(resolve, 1000));
    toast.success('Booking request sent successfully!');
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-12 h-12 text-primary-600 animate-spin mx-auto" />
          <p className="mt-4 text-gray-500">Loading listing...</p>
        </div>
      </div>
    );
  }

  if (!listing) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Listing not found</h2>
          <button
            onClick={() => navigate('/')}
            className="text-primary-600 hover:text-primary-700 flex items-center space-x-2"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Back to listings</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <button
          onClick={() => navigate('/')}
          className="text-primary-600 hover:text-primary-700 flex items-center space-x-2 mb-8"
        >
          <ArrowLeft className="w-5 h-5" />
          <span>Back to listings</span>
        </button>

        <div className="bg-white rounded-xl overflow-hidden shadow-lg border border-gray-200">
          <div className="p-8">
            <ImageGallery 
              images={listing.images} 
              alt={listing.bagSpecs.model} 
            />

            <div className="mt-8">
              <ListingHeader listing={listing} />
            </div>

            <div className="grid md:grid-cols-2 gap-8 mt-8">
              <div className="space-y-8">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">The bag</h2>
                  <p className="text-gray-600">{listing.description}</p>
                </div>

                <div>
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Bag specs</h2>
                  <div className="bg-gray-50 rounded-lg p-6 space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">
                          <Scale className="w-4 h-4 inline mr-1" />
                          Weight Capacity
                        </h4>
                        <p className="text-gray-900">
                          Empty: {listing.bagSpecs.weight.empty}g
                          <br />
                          Max Load: {listing.bagSpecs.weight.maxLoad}g
                        </p>
                      </div>

                      <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">
                          <Ruler className="w-4 h-4 inline mr-1" />
                          Wheel Size
                        </h4>
                        <p className="text-gray-900">
                          {listing.bagSpecs.wheelSize.min}" - {listing.bagSpecs.wheelSize.max}"
                        </p>
                        {listing.bagSpecs.wheelbase?.max && (
                          <p className="text-gray-900 mt-1">
                            Max Wheelbase: {listing.bagSpecs.wheelbase.max}cm
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <h4 className="text-sm font-medium text-gray-500 mb-2">
                        <Lock className="w-4 h-4 inline mr-1" />
                        Security Features
                      </h4>
                      <ul className="list-disc list-inside text-gray-900">
                        {listing.bagSpecs.securityFeatures.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <BookingWidget 
                  listing={listing}
                  onBookingSubmit={handleBookingSubmit}
                />

                <div className="mt-6 bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Share this listing</h3>
                  <ShareListing listing={listing} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {showAuthModal && (
        <AuthModal 
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          initialMode="login"
        />
      )}
    </div>
  );
};

export default ListingDetails;