import React from 'react';
import { Shield, Key, CreditCard, FileText, User } from 'lucide-react';
import * as Switch from '@radix-ui/react-switch';

interface SettingsTabProps {
  selectedBag?: string | null;
}

const SettingsTab: React.FC<SettingsTabProps> = ({ selectedBag }) => {
  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">
          {selectedBag ? `${selectedBag} Settings` : 'Account Settings'}
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          {selectedBag 
            ? 'Configure settings specific to this bag model'
            : 'Manage your account preferences and settings'
          }
        </p>
      </div>

      <div className="space-y-6 max-w-3xl">
        {/* Security Settings */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <Shield className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Security</h3>
              <p className="text-sm text-gray-500">Manage your account security settings</p>
            </div>
          </div>

          <div className="space-y-6">
            <div>
              <h4 className="text-sm font-medium text-gray-900">Two-Factor Authentication</h4>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm text-gray-500">Add an extra layer of security to your account</p>
                <Switch.Root
                  className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                >
                  <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
                </Switch.Root>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900">Password</h4>
              <div className="mt-2">
                <button className="text-primary-500 hover:text-primary-600 text-sm font-medium">
                  Change password
                </button>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900">Login History</h4>
              <div className="mt-2">
                <button className="text-primary-500 hover:text-primary-600 text-sm font-medium">
                  View login activity
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Settings */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <CreditCard className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Payment Methods</h3>
              <p className="text-sm text-gray-500">Manage your payment and payout methods</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 bg-blue-500 rounded-lg flex items-center justify-center text-white">
                  Visa
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">•••• 4242</p>
                  <p className="text-sm text-gray-500">Expires 12/24</p>
                </div>
              </div>
              <button className="text-primary-500 hover:text-primary-600 text-sm font-medium">
                Edit
              </button>
            </div>

            <button className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              Add Payment Method
            </button>
          </div>
        </div>

        {/* Rental Policies */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <FileText className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Rental Policies</h3>
              <p className="text-sm text-gray-500">Configure your rental terms and conditions</p>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Cancellation Policy</label>
              <select className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md">
                <option>Flexible (24 hours)</option>
                <option>Moderate (3 days)</option>
                <option>Strict (7 days)</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">ID Verification</label>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm text-gray-500">Require renters to verify their identity</p>
                <Switch.Root
                  className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                  defaultChecked
                >
                  <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
                </Switch.Root>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Insurance Requirements</label>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm text-gray-500">Require renters to have insurance</p>
                <Switch.Root
                  className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                  defaultChecked
                >
                  <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
                </Switch.Root>
              </div>
            </div>
          </div>
        </div>

        {/* Account Preferences */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <User className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Account Preferences</h3>
              <p className="text-sm text-gray-500">Manage your account settings</p>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Language</label>
              <select className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md">
                <option>English</option>
                <option>Spanish</option>
                <option>French</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Time Zone</label>
              <select className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md">
                <option>Pacific Time (PT)</option>
                <option>Mountain Time (MT)</option>
                <option>Central Time (CT)</option>
                <option>Eastern Time (ET)</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Currency</label>
              <select className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md">
                <option>USD ($)</option>
                <option>EUR (€)</option>
                <option>GBP (£)</option>
                <option>AUD ($)</option>
              </select>
            </div>
          </div>
        </div>

        {/* Danger Zone */}
        <div className="bg-red-50 rounded-lg border border-red-200 p-6">
          <h3 className="text-lg font-semibold text-red-800 mb-4">Danger Zone</h3>
          <div className="space-y-4">
            <button className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors">
              Deactivate Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;