import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { MapPin, Filter, DollarSign, Bike } from 'lucide-react';
import { useBrands } from '../context/BrandContext';

const searchSchema = z.object({
  location: z.string().optional(),
  bikeType: z.enum(['roadBike', 'mountainBike', 'hybridBike', 'ebike', 'bmx', 'folding']).optional(),
  brand: z.string().optional(),
  maxDailyRate: z.number().min(0).optional(),
});

type SearchInputs = z.infer<typeof searchSchema>;

interface SearchFiltersProps {
  onSearch: (filters: SearchInputs) => void;
  isOpen: boolean;
}

const SearchFilters: React.FC<SearchFiltersProps> = ({ onSearch, isOpen }) => {
  const { brands } = useBrands();
  
  const { register, handleSubmit } = useForm<SearchInputs>({
    resolver: zodResolver(searchSchema),
  });
  
  const bikeTypes = [
    { value: 'roadBike', label: 'Road Bike' },
    { value: 'mountainBike', label: 'Mountain Bike' },
    { value: 'hybridBike', label: 'Hybrid Bike' },
    { value: 'ebike', label: 'Electric Bike' },
    { value: 'bmx', label: 'BMX' },
    { value: 'folding', label: 'Folding Bike' },
  ];

  if (!isOpen) return null;

  return (
    <form 
      onSubmit={handleSubmit(onSearch)}
      className="bg-white rounded-lg shadow-lg p-6 space-y-6"
    >
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Location
        </label>
        <div className="relative">
          <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            {...register('location')}
            placeholder="City, region, or country"
            className="pl-10 w-full rounded-lg border-gray-300 focus:border-primary-500 focus:ring-primary-500"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Bike Type
          <Bike className="inline-block ml-1 w-4 h-4 text-gray-400" />
        </label>
        <select
          {...register('bikeType')}
          className="w-full rounded-lg border-gray-300 focus:border-primary-500 focus:ring-primary-500"
        >
          <option value="">Any bike type</option>
          {bikeTypes.map(({ value, label }) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Brand
        </label>
        <select
          {...register('brand')}
          className="w-full rounded-lg border-gray-300 focus:border-primary-500 focus:ring-primary-500"
        >
          <option value="">Any brand</option>
          {brands.map(brand => (
            <option key={brand.id} value={brand.id}>{brand.name}</option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Maximum Daily Rate
        </label>
        <div className="relative">
          <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="number"
            min="0"
            step="0.01"
            {...register('maxDailyRate', { valueAsNumber: true })}
            placeholder="Enter your budget"
            className="pl-10 w-full rounded-lg border-gray-300 focus:border-primary-500 focus:ring-primary-500"
          />
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-primary-500 text-white py-2 rounded-lg hover:bg-primary-600 transition duration-200 flex items-center justify-center space-x-2"
      >
        <Filter className="w-4 h-4" />
        <span>Apply Filters</span>
      </button>
    </form>
  );
};

export default SearchFilters;