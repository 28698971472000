import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { 
  User as UserIcon, Mail, Building2, Bell, MapPin,
  ArrowLeft, Shield, Phone
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import ProfileImageUpload from '../components/ProfileImageUpload';
import { uploadProfileImage } from '../lib/storage';
import PhoneInput from 'react-phone-input-2';
import AddressForm from '../components/AddressForm';
import 'react-phone-input-2/lib/style.css';

const phoneRegex = /^\+[1-9]\d{1,14}$/;

const profileSchema = z.object({
  firstName: z.string().min(2, 'First name must be at least 2 characters'),
  lastName: z.string().min(2, 'Last name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  mobile: z.string().regex(phoneRegex, 'Invalid mobile number format').optional(),
  address: z.object({
    street: z.string().min(5, 'Street address is required'),
    unit: z.string().optional(),
    city: z.string().min(2, 'City is required'),
    state: z.string().min(2, 'State is required'),
    postalCode: z.string().min(3, 'Postal code is required'),
    country: z.string().min(2, 'Country is required'),
    coordinates: z.object({
      lat: z.number(),
      lng: z.number(),
    }),
    privacy: z.enum(['public', 'city_only']),
  }).optional(),
  notificationPreferences: z.object({
    email: z.boolean(),
    sms: z.boolean(),
  }),
});

type ProfileInputs = z.infer<typeof profileSchema>;

const UserProfile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const navigate = useNavigate();
  const [newProfileImage, setNewProfileImage] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ProfileInputs>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      mobile: user?.mobile || '',
      address: user?.address,
      notificationPreferences: user?.notificationPreferences || {
        email: true,
        sms: false,
      },
    },
  });

  const onSubmit = async (data: ProfileInputs) => {
    try {
      setIsSaving(true);
      
      let profileImage = user?.profileImage;
      if (newProfileImage) {
        profileImage = await uploadProfileImage(newProfileImage, user.id);
      }
      
      await updateUser({
        ...data,
        profileImage,
        updatedAt: new Date(),
      });
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert('Failed to update profile. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  if (!user) {
    navigate('/');
    return null;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-600 hover:text-gray-900 mb-4"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back
          </button>
          <h1 className="text-2xl font-bold text-gray-900">Profile Settings</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage your personal information and preferences
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {/* Personal Information */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex items-center space-x-3 mb-6">
              <UserIcon className="w-6 h-6 text-primary-500" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Personal Information</h2>
                <p className="text-sm text-gray-500">Your basic account details</p>
              </div>
            </div>
            
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Profile Photo
              </label>
              <ProfileImageUpload
                currentImage={user?.profileImage}
                firstName={user?.firstName}
                lastName={user?.lastName}
                onChange={setNewProfileImage}
              />
            </div>
            
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      {...register('firstName')}
                      className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="John"
                    />
                  </div>
                  {errors.firstName && (
                    <p className="mt-1 text-sm text-red-600">{errors.firstName.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      {...register('lastName')}
                      className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="Doe"
                    />
                  </div>
                  {errors.lastName && (
                    <p className="mt-1 text-sm text-red-600">{errors.lastName.message}</p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    {...register('email')}
                    type="email"
                    className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    placeholder="you@example.com"
                  />
                </div>
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
                )}
                {user.verifiedEmail && (
                  <p className="mt-1 text-sm text-green-600 flex items-center">
                    <Shield className="w-4 h-4 mr-1" />
                    Verified email
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mobile Number
                </label>
                <div className="mt-1">
                  <Controller
                    name="mobile"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        country="au"
                        value={value}
                        onChange={phone => onChange(`+${phone}`)}
                        inputClass="!w-full !bg-white !text-gray-900 !rounded-md !pl-12 !pr-4 !py-2 !border-gray-300 focus:!ring-2 focus:!ring-primary-500 focus:!border-primary-500"
                        buttonClass="!border-gray-300 !rounded-l-md hover:!bg-gray-50"
                        dropdownClass="!bg-white !text-gray-900"
                        searchClass="!bg-white !text-gray-900"
                        containerClass="!rounded-md"
                        searchPlaceholder="Search country..."
                        enableSearch
                        disableSearchIcon
                        countryCodeEditable={false}
                        preferredCountries={['au', 'nz', 'gb', 'us', 'ca', 'ie']}
                      />
                    )}
                  />
                </div>
                {errors.mobile && (
                  <p className="mt-1 text-sm text-red-600">{errors.mobile.message}</p>
                )}
                {user.verifiedMobile && (
                  <p className="mt-1 text-sm text-green-600 flex items-center">
                    <Shield className="w-4 h-4 mr-1" />
                    Verified number
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Address Information */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex items-center space-x-3 mb-6">
              <MapPin className="w-6 h-6 text-primary-500" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Address</h2>
                <p className="text-sm text-gray-500">Your location information</p>
              </div>
            </div>
            <AddressForm
              register={register}
              setValue={setValue}
              errors={errors}
              defaultAddress={user.address}
            />
          </div>

          {/* Notification Preferences */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex items-center space-x-3 mb-6">
              <Bell className="w-6 h-6 text-primary-500" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Notification Preferences</h2>
                <p className="text-sm text-gray-500">Choose how you want to receive updates</p>
              </div>
            </div>

            <div className="space-y-4">
              <label className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    {...register('notificationPreferences.email')}
                    className="h-4 w-4 text-primary-500 border-gray-300 rounded focus:ring-primary-500"
                  />
                </div>
                <div className="ml-3">
                  <span className="text-sm font-medium text-gray-700">Email notifications</span>
                  <p className="text-sm text-gray-500">Receive updates and alerts via email</p>
                </div>
              </label>

              <label className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    {...register('notificationPreferences.sms')}
                    disabled={!user.mobile}
                    className="h-4 w-4 text-primary-500 border-gray-300 rounded focus:ring-primary-500 disabled:opacity-50"
                  />
                </div>
                <div className="ml-3">
                  <span className="text-sm font-medium text-gray-700">
                    SMS notifications
                    {!user.mobile && (
                      <span className="text-xs text-gray-500 ml-2">
                        (Requires verified mobile number)
                      </span>
                    )}
                  </span>
                  <p className="text-sm text-gray-500">Get important alerts via text message</p>
                </div>
              </label>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
            >
              {isSaving ? 'Saving Changes...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;