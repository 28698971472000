import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  User, LogOut, Plus, Settings, Menu,
  LayoutDashboard, X
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import AdminMenu from './AdminMenu';
import useAdmin from '../hooks/useAdmin';

const UserMenu: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user) {
    return (
      <div className="flex items-center space-x-4">
        <Link
          to="/sign-in"
          className="text-gray-700 hover:text-gray-900 font-medium"
        >
          Sign in
        </Link>
        <Link
          to="/sign-up"
          className="bg-primary-500 hover:bg-primary-600 text-white px-4 py-2 rounded-lg transition duration-200"
        >
          Sign up
        </Link>
      </div>
    );
  }

  const initials = [
    user.firstName?.[0] || '',
    user.lastName?.[0] || ''
  ].filter(Boolean).join('') || '?';

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 font-medium"
      >
        <div className="md:flex items-center space-x-2 hidden">
          <span>{user.firstName || 'User'}</span>
          <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
            <span className="text-sm font-medium">{initials}</span>
          </div>
        </div>
        <Menu className="w-6 h-6 md:hidden" />
      </button>

      {/* Backdrop for mobile menu */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-[998] md:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Mobile Slide-out Menu */}
      <div
        className={`fixed inset-y-0 right-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-[999] md:hidden ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                <span className="text-lg font-medium">{initials}</span>
              </div>
              <div>
                <div className="font-medium">{user.firstName} {user.lastName}</div>
                <div className="text-sm text-gray-500">{user.email}</div>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:text-gray-600"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <nav className="p-4 space-y-1">
          <Link
            to="/dashboard"
            className="flex items-center space-x-3 px-4 py-3 text-gray-700 hover:bg-gray-50 rounded-lg"
            onClick={() => setIsOpen(false)}
          >
            <LayoutDashboard className="w-5 h-5" />
            <span>Dashboard</span>
          </Link>
          <Link
            to="/profile"
            className="flex items-center space-x-3 px-4 py-3 text-gray-700 hover:bg-gray-50 rounded-lg"
            onClick={() => setIsOpen(false)}
          >
            <Settings className="w-5 h-5" />
            <span>Profile Settings</span>
          </Link>
          <Link
            to="/new-listing"
            className="flex items-center space-x-3 px-4 py-3 text-gray-700 hover:bg-gray-50 rounded-lg"
            onClick={() => setIsOpen(false)}
          >
            <Plus className="w-5 h-5" />
            <span>New Listing</span>
          </Link>
          {isAdmin && <AdminMenu />}
          <button
            onClick={async () => {
              await signOut();
              setIsOpen(false);
              navigate('/');
            }}
            className="flex items-center space-x-3 px-4 py-3 text-red-600 hover:bg-red-50 rounded-lg w-full"
          >
            <LogOut className="w-5 h-5" />
            <span>Sign Out</span>
          </button>
        </nav>
      </div>

      {/* Desktop Dropdown Menu */}
      <div
        className={`absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 hidden md:block z-50 ${
          isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
        } transition-all duration-200`}
      >
        <Link
          to="/profile"
          className="block px-4 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-50"
        >
          <div className="flex items-center space-x-2">
            <Settings className="w-4 h-4" />
            <span>Profile Settings</span>
          </div>
        </Link>
        <Link
          to="/dashboard"
          className="block px-4 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-50"
        >
          <div className="flex items-center space-x-2">
            <LayoutDashboard className="w-4 h-4" />
            <span>Dashboard</span>
          </div>
        </Link>
        <Link
          to="/new-listing"
          className="block px-4 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-50"
        >
          <div className="flex items-center space-x-2">
            <Plus className="w-4 h-4" />
            <span>New Listing</span>
          </div>
        </Link>
        {isAdmin && <AdminMenu />}
        <button
          onClick={async () => {
            await signOut();
            navigate('/');
          }}
          className="w-full text-left px-4 py-2 text-red-600 hover:text-red-700 hover:bg-red-50 flex items-center space-x-2"
        >
          <LogOut className="w-4 h-4" />
          <span>Sign Out</span>
        </button>
      </div>
    </div>
  );
};

export default UserMenu;