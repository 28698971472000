import React, { createContext, useContext, useState, useEffect } from 'react';
import { User as FirebaseUser } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { signUp, signIn, signOut, resetPassword, resendVerificationEmail, onAuthChange } from '../lib/auth';
import type { User } from '../types';
import toast from 'react-hot-toast';

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  signUp: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  updateUser: (data: Partial<User>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const initAuth = async () => {
      try {
        unsubscribe = onAuthChange(async (firebaseUser: FirebaseUser | null) => {
          try {
            if (firebaseUser) {
              const userRef = doc(db, 'users', firebaseUser.uid);
              const userSnap = await getDoc(userRef);
              
              if (userSnap.exists()) {
                const userData = { id: userSnap.id, ...userSnap.data() } as User;
                
                // Update email verification status and last login
                if (userData.verifiedEmail !== firebaseUser.emailVerified) {
                  await setDoc(userRef, {
                    verifiedEmail: firebaseUser.emailVerified,
                    lastLogin: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                  }, { merge: true });
                  
                  userData.verifiedEmail = firebaseUser.emailVerified;
                  if (firebaseUser.emailVerified) {
                    toast.success('Email verified successfully!');
                  }
                }
                
                setUser(userData);
              } else {
                // Create default user document if it doesn't exist
                const defaultUserData: Omit<User, 'id'> = {
                  email: firebaseUser.email!,
                  firstName: firebaseUser.displayName?.split(' ')[0] || '',
                  lastName: firebaseUser.displayName?.split(' ')[1] || '',
                  accountType: 'personal',
                  role: 'user',
                  status: 'active',
                  listings: [],
                  createdAt: new Date().toISOString(),
                  lastLogin: new Date().toISOString(),
                  verifiedEmail: firebaseUser.emailVerified,
                  notificationPreferences: {
                    email: true,
                    sms: false,
                  },
                };

                await setDoc(userRef, {
                  ...defaultUserData,
                  updatedAt: serverTimestamp(),
                });

                setUser({ id: firebaseUser.uid, ...defaultUserData });
              }
            } else {
              setUser(null);
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('Error loading user data');
            setUser(null);
          } finally {
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error('Error initializing auth:', error);
        setIsLoading(false);
      }
    };

    initAuth();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const handleUpdateUser = async (data: Partial<User>) => {
    try {
      if (!user) {
        throw new Error('No user logged in');
      }

      const userRef = doc(db, 'users', user.id);
      await setDoc(userRef, {
        ...data,
        updatedAt: serverTimestamp(),
      }, { merge: true });

      setUser(prevUser => prevUser ? { ...prevUser, ...data } : null);
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update profile');
      throw error;
    }
  };

  const value = {
    user,
    isLoading,
    signUp,
    signIn,
    signOut,
    resetPassword,
    resendVerificationEmail,
    updateUser: handleUpdateUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}