import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { MapPin, DollarSign, Truck, Loader2, ArrowLeft } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import ImageUpload from '../components/ImageUpload';
import { createListing } from '../lib/db/listings';
import { listBags } from '../lib/db/bags';
import type { Bag } from '../types';
import toast from 'react-hot-toast';

const listingSchema = z.object({
  bagId: z.string().min(1, 'Please select a bag'),
  location: z.string().min(2, 'Location is required'),
  description: z.string().min(10, 'Description must be at least 10 characters'),
  pricingSchedule: z.object({
    minimumDays: z.number().min(1, 'Minimum rental duration must be at least 1 day'),
    dailyRate: z.number().min(1, 'Daily rate must be greater than 0'),
    weeklyRate: z.number().min(1, 'Weekly rate must be greater than 0'),
  }),
  deliveryOptions: z.object({
    pickup: z.boolean(),
    dropoff: z.boolean(),
    delivery: z.boolean(),
    deliveryFee: z.number().optional(),
    deliveryRadius: z.number().optional(),
    pickupLocation: z.string().optional(),
  }),
});

type ListingInputs = z.infer<typeof listingSchema>;

const NewListing: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [images, setImages] = useState<File[]>([]);
  const [bags, setBags] = useState<Bag[]>([]);
  const [selectedBag, setSelectedBag] = useState<Bag | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [brands, setBrands] = useState<{ [key: string]: Bag[] }>({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ListingInputs>({
    resolver: zodResolver(listingSchema),
    defaultValues: {
      pricingSchedule: {
        minimumDays: 1,
        dailyRate: 0,
        weeklyRate: 0,
      },
      deliveryOptions: {
        pickup: true,
        dropoff: false,
        delivery: false,
      },
    },
  });

  useEffect(() => {
    const loadBags = async () => {
      try {
        const loadedBags = await listBags();
        setBags(loadedBags);
        
        // Group bags by brand
        const groupedBags = loadedBags.reduce((acc, bag) => {
          const brand = bag.brand || 'Other';
          if (!acc[brand]) {
            acc[brand] = [];
          }
          acc[brand].push(bag);
          return acc;
        }, {} as { [key: string]: Bag[] });
        
        setBrands(groupedBags);
      } catch (error) {
        console.error('Failed to load bags:', error);
        toast.error('Failed to load bags');
      } finally {
        setIsLoading(false);
      }
    };

    loadBags();
  }, []);

  const selectedBagId = watch('bagId');
  const deliveryEnabled = watch('deliveryOptions.delivery');

  useEffect(() => {
    if (selectedBagId) {
      const bag = bags.find(b => b.id === selectedBagId);
      setSelectedBag(bag || null);
    }
  }, [selectedBagId, bags]);

  const onSubmit = async (data: ListingInputs) => {
    try {
      if (!user) {
        throw new Error('You must be logged in to create a listing');
      }

      if (!user.address) {
        throw new Error('You must set your address before creating a listing');
      }

      if (images.length === 0) {
        throw new Error('Please upload at least one image');
      }

      if (!selectedBag) {
        throw new Error('Please select a bag');
      }

      const listingData = {
        ...data,
        bagSpecs: selectedBag,
        owner: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          address: user.address,
        },
        images: [], // Will be populated after image upload
        thumbnailIndex,
        available: true,
        featured: false,
        rating: 0,
        reviews: 0,
        upvotes: 0,
      };

      const newListing = await createListing(listingData, images);
      navigate(`/listing/${newListing.id}`, {
        state: { newListing: true }
      });
    } catch (error: any) {
      console.error('Failed to create listing:', error);
      toast.error(error.message || 'Failed to create listing');
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto" />
          <p className="mt-4 text-gray-500">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <div className="mb-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back
          </button>
          <h1 className="text-2xl font-bold text-gray-900 mt-4">Create New Listing</h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Upload Images
            </label>
            <ImageUpload
              images={images}
              onChange={setImages}
              maxImages={5}
              maxSize={5 * 1024 * 1024}
              thumbnailIndex={thumbnailIndex}
              onThumbnailChange={setThumbnailIndex}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Select Bag
            </label>
            <select
              {...register('bagId')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            >
              <option value="">Choose a bag...</option>
              {Object.entries(brands).map(([brand, brandBags]) => (
                <optgroup key={brand} label={brand}>
                  {brandBags.map((bag) => (
                    <option key={bag.id} value={bag.id}>
                      {bag.model}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            {errors.bagId && (
              <p className="mt-1 text-sm text-red-600">{errors.bagId.message}</p>
            )}
          </div>

          {selectedBag && (
            <div className="bg-gray-50 rounded-lg p-4 space-y-4">
              <h3 className="font-medium text-gray-900">Selected Bag Details</h3>
              <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                <div>
                  <p>Protection Level: {selectedBag.protectionLevel}/5</p>
                  <p>TSA Compliant: {selectedBag.tsaCompliant}</p>
                </div>
                <div>
                  <p>Wheel Size: {selectedBag.wheelSize.min}" - {selectedBag.wheelSize.max}"</p>
                  <p>Weight: {selectedBag.weight.empty}g (empty)</p>
                </div>
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Location
            </label>
            <div className="bg-gray-50 rounded-lg p-4 mt-1">
              <div className="flex items-center text-gray-600">
                <MapPin className="w-5 h-5 mr-2" />
                <span>Listing location will be set to: {user?.address?.city}, {user?.address?.state}</span>
              </div>
            </div>
            {errors.location && (
              <p className="mt-1 text-sm text-red-600">{errors.location.message}</p>
            )}
          </div>

          <div className="bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Pricing Schedule</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimum Days
                </label>
                <input
                  type="number"
                  min="1"
                  {...register('pricingSchedule.minimumDays', { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
                {errors.pricingSchedule?.minimumDays && (
                  <p className="mt-1 text-sm text-red-600">{errors.pricingSchedule.minimumDays.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Daily Rate
                </label>
                <div className="relative mt-1">
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    {...register('pricingSchedule.dailyRate', { valueAsNumber: true })}
                    className="block w-full pl-10 pr-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  />
                </div>
                {errors.pricingSchedule?.dailyRate && (
                  <p className="mt-1 text-sm text-red-600">{errors.pricingSchedule.dailyRate.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Weekly Rate
                </label>
                <div className="relative mt-1">
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    {...register('pricingSchedule.weeklyRate', { valueAsNumber: true })}
                    className="block w-full pl-10 pr-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  />
                </div>
                {errors.pricingSchedule?.weeklyRate && (
                  <p className="mt-1 text-sm text-red-600">{errors.pricingSchedule.weeklyRate.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Delivery Options</h3>
            <div className="space-y-6">
              <div className="flex flex-wrap gap-6">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register('deliveryOptions.pickup')}
                    className="rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                  />
                  <span className="text-gray-700">Pickup available</span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register('deliveryOptions.dropoff')}
                    className="rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                  />
                  <span className="text-gray-700">Drop-off available</span>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register('deliveryOptions.delivery')}
                    className="rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                  />
                  <span className="text-gray-700">Delivery available</span>
                </label>
              </div>

              {deliveryEnabled && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Delivery Fee
                    </label>
                    <div className="relative mt-1">
                      <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        {...register('deliveryOptions.deliveryFee', { valueAsNumber: true })}
                        className="block w-full pl-10 pr-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Delivery Radius (km)
                    </label>
                    <input
                      type="number"
                      min="1"
                      {...register('deliveryOptions.deliveryRadius', { valueAsNumber: true })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              {...register('description')}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder="Describe your listing, including any special instructions or requirements"
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-6 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 flex items-center space-x-2"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  <span>Creating Listing...</span>
                </>
              ) : (
                <span>Create Listing</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewListing;