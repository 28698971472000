import React from 'react';
import { Bell, MessageCircle, Mail, Calendar, Shield } from 'lucide-react';
import * as Switch from '@radix-ui/react-switch';

const NotificationsTab: React.FC = () => {
  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Notifications</h2>
        <p className="mt-1 text-sm text-gray-500">
          Manage your notification preferences and alerts
        </p>
      </div>

      <div className="space-y-6 max-w-3xl">
        {/* Email Notifications */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <Mail className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Email Notifications</h3>
              <p className="text-sm text-gray-500">Configure email alert settings</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">New Booking Requests</p>
                <p className="text-sm text-gray-500">Get notified when you receive a new booking</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                defaultChecked
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Booking Updates</p>
                <p className="text-sm text-gray-500">Receive updates about your bookings</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                defaultChecked
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Reminders</p>
                <p className="text-sm text-gray-500">Get reminded about upcoming pickups and returns</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                defaultChecked
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>
          </div>
        </div>

        {/* SMS Notifications */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <MessageCircle className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">SMS Notifications</h3>
              <p className="text-sm text-gray-500">Configure text message alerts</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Urgent Updates</p>
                <p className="text-sm text-gray-500">Receive time-sensitive notifications via SMS</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Booking Confirmations</p>
                <p className="text-sm text-gray-500">Get SMS confirmations for new bookings</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>
          </div>
        </div>

        {/* Calendar Sync */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <Calendar className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Calendar Sync</h3>
              <p className="text-sm text-gray-500">Sync bookings with your calendar</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Google Calendar</p>
                <p className="text-sm text-gray-500">Sync with your Google Calendar</p>
              </div>
              <button className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors">
                Connect
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Apple Calendar</p>
                <p className="text-sm text-gray-500">Sync with your iCloud Calendar</p>
              </div>
              <button className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors">
                Connect
              </button>
            </div>
          </div>
        </div>

        {/* Security Alerts */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-3 mb-6">
            <Shield className="w-6 h-6 text-primary-500" />
            <div>
              <h3 className="text-lg font-semibold text-gray-900">Security Alerts</h3>
              <p className="text-sm text-gray-500">Configure security notification preferences</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Login Alerts</p>
                <p className="text-sm text-gray-500">Get notified of new device logins</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                defaultChecked
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Account Changes</p>
                <p className="text-sm text-gray-500">Get notified of important account changes</p>
              </div>
              <Switch.Root
                className="w-11 h-6 bg-gray-200 rounded-full relative data-[state=checked]:bg-primary-500 outline-none cursor-pointer"
                defaultChecked
              >
                <Switch.Thumb className="block w-4 h-4 bg-white rounded-full transition-transform duration-100 translate-x-1 data-[state=checked]:translate-x-6" />
              </Switch.Root>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsTab;