import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import DashboardTabs from '../components/dashboard/DashboardTabs';

function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-50">
      <DashboardTabs />
    </div>
  );
}

export default Dashboard;