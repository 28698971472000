import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import { Star, Shield, MessageCircle, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import type { User } from '../types';

interface OwnerHoverCardProps {
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    profileImage?: string;
    rating?: number;
    reviews?: number;
    responseTime?: string;
    responseRate?: number;
    verifiedIdentity?: boolean;
    joinedDate?: Date;
  };
  children: React.ReactNode;
}

const OwnerHoverCard: React.FC<OwnerHoverCardProps> = ({ owner, children }) => {
  return (
    <HoverCard.Root openDelay={200} closeDelay={100}>
      <HoverCard.Trigger asChild>
        {children}
      </HoverCard.Trigger>
      
      <HoverCard.Portal>
        <HoverCard.Content
          className="w-80 bg-white rounded-lg shadow-lg border border-gray-200 p-4 animate-in fade-in zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          sideOffset={5}
          align="start"
        >
          <div className="space-y-4">
            {/* Header */}
            <div className="flex items-center space-x-3">
              <UserAvatar
                size="lg"
                profileImage={owner.profileImage}
                firstName={owner.firstName}
                lastName={owner.lastName}
              />
              <div>
                <h3 className="font-medium text-gray-900">
                  {owner.firstName} {owner.lastName}
                </h3>
                <p className="text-sm text-gray-500">
                  Member since {owner.joinedDate?.getFullYear() || 'N/A'}
                </p>
              </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className="flex items-center text-gray-600">
                <Star className="w-4 h-4 text-yellow-400 mr-1.5" />
                <span>
                  {owner.rating || 'No'} {owner.rating === 1 ? 'rating' : 'ratings'}
                </span>
              </div>
              <div className="flex items-center text-gray-600">
                <MessageCircle className="w-4 h-4 mr-1.5" />
                <span>{owner.reviews || 0} reviews</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Clock className="w-4 h-4 mr-1.5" />
                <span>{owner.responseTime || 'N/A'} response</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Shield className="w-4 h-4 mr-1.5" />
                {owner.verifiedIdentity ? (
                  <span className="text-green-600">Verified</span>
                ) : (
                  <span>Not verified</span>
                )}
              </div>
            </div>

            {/* View Profile Link */}
            <Link
              to={`/owner/${owner.id}`}
              className="block w-full text-center bg-primary-500 text-white py-2 rounded-lg hover:bg-primary-600 transition-colors"
            >
              View Full Profile
            </Link>
          </div>

          <HoverCard.Arrow className="fill-white" />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default OwnerHoverCard;