import React, { useState } from 'react';
import { useUsers } from '../context/UserContext';
import { 
  Mail, Calendar, Shield, AlertCircle, Check, X, Edit2, 
  UserX, UserCheck, Plus, Building2, Link as LinkIcon,
  Loader2
} from 'lucide-react';
import type { User } from '../types';
import UserEditModal from './UserEditModal';
import { Toast } from './Toast';
import toast from 'react-hot-toast';

const UserManagement: React.FC = () => {
  const { users, updateUser, deleteUser } = useUsers();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [filter, setFilter] = useState<'all' | 'personal' | 'business'>('all');

  const filteredUsers = users?.filter(user => {
    switch (filter) {
      case 'personal':
        return user.accountType === 'personal';
      case 'business':
        return user.accountType === 'business';
      default:
        return true;
    }
  });

  const handleStatusChange = async (userId: string, status: User['status']) => {
    try {
      await updateUser(userId, { status });
      toast.custom((t) => (
        <Toast
          type="success"
          title="Status Updated"
          description={`User status has been updated to ${status}`}
          open={t.visible}
          onOpenChange={t.dismiss}
        />
      ));
    } catch (error) {
      console.error('Failed to update user status:', error);
      toast.custom((t) => (
        <Toast
          type="error"
          title="Update Failed"
          description="Failed to update user status"
          open={t.visible}
          onOpenChange={t.dismiss}
        />
      ));
    }
  };

  const handleRoleChange = async (userId: string, role: User['role']) => {
    try {
      await updateUser(userId, { role });
      toast.success(`User role updated to ${role}`);
    } catch (error) {
      console.error('Failed to update user role:', error);
      toast.error('Failed to update user role');
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        await deleteUser(userId);
        toast.success('User deleted successfully');
      } catch (error) {
        console.error('Failed to delete user:', error);
        toast.error('Failed to delete user');
      }
    }
  };

  const handleSaveUser = async (userId: string, data: Partial<User>) => {
    try {
      await updateUser(userId, data);
      toast.success('User updated successfully');
    } catch (error) {
      console.error('Failed to update user:', error);
      toast.error('Failed to update user');
    }
  };

  const formatDate = (date: Date | string) => {
    if (!date) return '-';
    
    try {
      const dateObj = typeof date === 'string' ? new Date(date) : date;
      if (isNaN(dateObj.getTime())) return '-';
      
      return dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  };

  const getStatusColor = (status: User['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'suspended':
        return 'bg-red-100 text-red-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (!users) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader2 className="w-8 h-8 text-primary-500 animate-spin" />
      </div>
    );
  }

  return (
    <div>
      {/* Filter Tabs */}
      <div className="mb-6">
        <nav className="flex space-x-4" aria-label="User filters">
          <button
            onClick={() => setFilter('all')}
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              filter === 'all'
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            All Users ({users.length})
          </button>
          <button
            onClick={() => setFilter('personal')}
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              filter === 'personal'
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Personal ({users.filter(u => u.accountType === 'personal').length})
          </button>
          <button
            onClick={() => setFilter('business')}
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              filter === 'business'
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Businesses ({users.filter(u => u.accountType === 'business').length})
          </button>
        </nav>
      </div>

      {/* Users List */}
      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        <ul className="divide-y divide-gray-200">
          {filteredUsers.map((user) => (
            <li key={user.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center">
                        {user.accountType === 'business' ? (
                          <Building2 className="w-5 h-5 text-primary-600" />
                        ) : (
                          <span className="text-lg font-medium text-primary-600">
                            {user.firstName?.charAt(0).toUpperCase() || '?'}
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        {user.accountType === 'business' 
                          ? user.businessInfo?.name 
                          : `${user.firstName} ${user.lastName}`}
                      </h3>
                      <div className="flex items-center text-sm text-gray-500">
                        <Mail className="w-4 h-4 mr-1" />
                        {user.email}
                        {user.verifiedEmail && (
                          <Check className="w-4 h-4 ml-1 text-green-500" />
                        )}
                      </div>
                      {user.accountType === 'business' && !user.businessInfo?.claimed && (
                        <div className="mt-1 flex items-center space-x-2">
                          <span className="text-xs bg-yellow-100 text-yellow-800 px-2 py-0.5 rounded-full">
                            Unclaimed
                          </span>
                          <button
                            onClick={() => {
                              const claimUrl = `${window.location.origin}/claim/${user.id}/${user.businessInfo?.claimToken}`;
                              navigator.clipboard.writeText(claimUrl);
                              alert('Claim URL copied to clipboard');
                            }}
                            className="text-xs text-primary-600 hover:text-primary-700 flex items-center"
                          >
                            <LinkIcon className="w-3 h-3 mr-1" />
                            Copy Claim URL
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-3 flex items-center space-x-6 text-sm text-gray-500">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      Joined {formatDate(user.createdAt)}
                    </div>
                    <div>
                      <select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user.id, e.target.value as User['role'])}
                        className="text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                    <div>
                      <select
                        value={user.status}
                        onChange={(e) => handleStatusChange(user.id, e.target.value as User['status'])}
                        className={`text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-500 focus:ring-primary-500`}
                      >
                        <option value="active">Active</option>
                        <option value="suspended">Suspended</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(user.status)}`}>
                      {user.status}
                    </span>
                  </div>
                </div>

                <div className="ml-6 flex items-center space-x-3">
                  <button
                    onClick={() => setSelectedUser(user)}
                    className="text-gray-400 hover:text-gray-500"
                    title="Edit user"
                  >
                    <Edit2 className="w-5 h-5" />
                  </button>
                  {user.status === 'active' ? (
                    <button
                      onClick={() => handleStatusChange(user.id, 'suspended')}
                      className="text-yellow-400 hover:text-yellow-500"
                      title="Suspend user"
                    >
                      <UserX className="w-5 h-5" />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleStatusChange(user.id, 'active')}
                      className="text-green-400 hover:text-green-500"
                      title="Activate user"
                    >
                      <UserCheck className="w-5 h-5" />
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteUser(user.id)}
                    className="text-red-400 hover:text-red-500"
                    title="Delete user"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {filteredUsers.length === 0 && (
          <div className="text-center py-12">
            {filter === 'business' ? (
              <>
                <Building2 className="w-12 h-12 text-gray-400 mx-auto" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No Businesses Found</h3>
                <p className="mt-1 text-sm text-gray-500">There are no businesses in the system yet.</p>
              </>
            ) : (
              <>
                <Shield className="w-12 h-12 text-gray-400 mx-auto" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No Users Found</h3>
                <p className="mt-1 text-sm text-gray-500">There are no users in the system yet.</p>
              </>
            )}
          </div>
        )}
      </div>

      {/* Edit Modal */}
      {selectedUser && (
        <UserEditModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          onSave={handleSaveUser}
        />
      )}
    </div>
  );
};

export default UserManagement;