// SendGrid template IDs
export const TEMPLATES = {
  WELCOME: 'd-b6d32af44ea447638ed502be93bf9fd0',
  BOOKING_REQUEST: 'd-ec66d2994659498583f03aadb2c86fb4',
  BOOKING_CONFIRMATION: 'd-7303a932be2745718f605d015c415bca',
  VERIFY_EMAIL: 'd-cbc8307186f647f6bcc751241b076a38',
  BOOKING_CANCELLED: 'd-f3ce99ee8ce249f5b89fd6339a7ea020',
  BOOKING_REMINDER: 'd-be353301c5a648bcaf04f3e28954565f',
  PASSWORD_RESET: 'd-a81fd6bd95df4bfa9cceb4a024c14faf'
} as const;

export type EmailTemplateId = keyof typeof TEMPLATES;