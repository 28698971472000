import React from 'react';
import { Link } from 'react-router-dom';
import { Package, LayoutDashboard } from 'lucide-react';
import UserMenu from './UserMenu';
import LocationSelector from './LocationSelector';
import { useAuth } from '../context/AuthContext';

const Header: React.FC = () => {
  const { user } = useAuth();

  return (
    <header className="bg-white border-b border-gray-100">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center space-x-2">
            <Package className="w-8 h-8 text-primary-300" />
            <span className="text-xl font-bold text-gray-900">packmyride</span>
          </Link>

          <div className="flex items-center space-x-4">
            {user && (
              <Link
                to="/dashboard"
                className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 font-medium"
              >
                <LayoutDashboard className="w-5 h-5" />
                <span>Dashboard</span>
              </Link>
            )}
            <LocationSelector />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;