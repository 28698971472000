import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Package, AlertCircle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { FirebaseError } from 'firebase/app';

const signInSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

type SignInInputs = z.infer<typeof signInSchema>;

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInInputs>({
    resolver: zodResolver(signInSchema),
  });

  const onSubmit = async (data: SignInInputs) => {
    try {
      setAuthError(null);
      await signIn(data.email, data.password);
      navigate('/');
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/invalid-credential':
            setAuthError('Invalid email or password. Please try again.');
            break;
          case 'auth/user-not-found':
            setAuthError('No account found with this email. Please sign up first.');
            break;
          case 'auth/wrong-password':
            setAuthError('Incorrect password. Please try again.');
            break;
          case 'auth/too-many-requests':
            setAuthError('Too many failed attempts. Please try again later.');
            break;
          default:
            setAuthError('An error occurred during sign in. Please try again.');
        }
      } else {
        setAuthError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="py-8">
        <div className="container mx-auto px-4">
          <Link to="/" className="flex items-center justify-center space-x-2">
            <Package className="w-10 h-10 text-primary-500" />
            <span className="text-2xl font-bold text-gray-900">packmyride</span>
          </Link>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-1 flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-md">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-3">Welcome back</h1>
            <p className="text-gray-600">
              New to packmyride?{' '}
              <Link to="/sign-up" className="text-primary-500 hover:text-primary-600 font-medium">
                Create an account
              </Link>
            </p>
          </div>

          {authError && (
            <div className="mb-6 bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
              <span>{authError}</span>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                {...register('email')}
                type="email"
                className="form-input"
                placeholder="you@example.com"
              />
              {errors.email && (
                <p className="form-error">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                {...register('password')}
                type="password"
                className="form-input"
                placeholder="••••••••"
              />
              {errors.password && (
                <p className="form-error">{errors.password.message}</p>
              )}
            </div>

            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Remember me
                </span>
              </label>

              <Link
                to="/forgot-password"
                className="text-sm text-primary-500 hover:text-primary-600 font-medium"
              >
                Forgot password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn-primary"
            >
              {isSubmitting ? 'Signing in...' : 'Sign in'}
            </button>
          </form>
        </div>
      </main>

      {/* Footer */}
      <footer className="py-8">
        <div className="container mx-auto px-4 text-center text-sm text-gray-600">
          © {new Date().getFullYear()} packmyride. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default SignIn;