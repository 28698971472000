import React from 'react';
import { Users } from 'lucide-react';
import UserManagement from '../components/UserManagement';

function AdminUserManager() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Users className="w-8 h-8 text-primary-500" />
          <div>
            <h2 className="text-2xl font-bold text-gray-900">User Management</h2>
            <p className="text-gray-500">Manage user accounts and permissions</p>
          </div>
        </div>
        <UserManagement />
      </div>
    </div>
  );
}

export default AdminUserManager;