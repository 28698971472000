import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  collection,
  serverTimestamp,
  FirestoreError,
  increment
} from 'firebase/firestore';
import { db } from '../firebase';
import { uploadImages } from '../storage';
import type { Listing } from '../../types';
import toast from 'react-hot-toast';

export async function createListing(data: Omit<Listing, 'id' | 'createdAt' | 'updatedAt'>, images: File[]) {
  try {
    // First upload images
    const imageUrls = await uploadImages(images, 'listings');

    // Create listing document
    const listingRef = doc(collection(db, 'listings'));
    const listingData: Listing = {
      ...data,
      id: listingRef.id,
      images: imageUrls,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    await setDoc(listingRef, {
      ...listingData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    // Update user's listings array
    const userRef = doc(db, 'users', data.owner.id);
    await updateDoc(userRef, {
      listings: [...(data.owner.listings || []), listingRef.id],
      updatedAt: serverTimestamp(),
    });

    return listingData;
  } catch (error: any) {
    console.error('Error creating listing:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw new Error(error.message || 'Failed to create listing');
  }
}

export async function getListing(id: string) {
  try {
    const listingRef = doc(db, 'listings', id);
    const listingSnap = await getDoc(listingRef);
    if (!listingSnap.exists()) return null;
    return { id: listingSnap.id, ...listingSnap.data() } as Listing;
  } catch (error: any) {
    console.error('Error getting listing:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw error;
  }
}

export async function updateListing(id: string, data: Partial<Listing>, newImages?: File[]) {
  try {
    const listingRef = doc(db, 'listings', id);
    
    let updateData: any = {
      ...data,
      updatedAt: serverTimestamp(),
    };

    // If new images were uploaded, handle them
    if (newImages?.length) {
      const imageUrls = await uploadImages(newImages, 'listings');
      updateData.images = imageUrls;
    }

    await updateDoc(listingRef, updateData);
  } catch (error: any) {
    console.error('Error updating listing:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw new Error(error.message || 'Failed to update listing');
  }
}

export const updateBagConditionRating = async (
  listingId: string,
  category: string,
  isUpvote: boolean
) => {
  try {
    const listingRef = doc(db, 'listings', listingId);
    const field = `conditionRatings.${category}.${isUpvote ? 'up' : 'down'}`;
    
    await updateDoc(listingRef, {
      [field]: increment(1),
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    console.error('Error updating bag condition rating:', error);
    throw error;
  }
};

export async function deleteListing(id: string) {
  try {
    const listingRef = doc(db, 'listings', id);
    const listingSnap = await getDoc(listingRef);
    
    if (!listingSnap.exists()) {
      throw new Error('Listing not found');
    }

    const listing = listingSnap.data() as Listing;

    // Remove listing ID from user's listings array
    const userRef = doc(db, 'users', listing.owner.id);
    await updateDoc(userRef, {
      listings: listing.owner.listings.filter(listingId => listingId !== id),
      updatedAt: serverTimestamp(),
    });

    // Delete the listing document
    await deleteDoc(listingRef);
  } catch (error: any) {
    console.error('Error deleting listing:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw new Error(error.message || 'Failed to delete listing');
  }
}

export async function listListings() {
  try {
    const q = query(collection(db, 'listings'), orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Listing[];
  } catch (error: any) {
    console.error('Error listing listings:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw error;
  }
}

export async function getFeaturedListings(limitCount = 6) {
  try {
    const q = query(
      collection(db, 'listings'),
      where('featured', '==', true),
      orderBy('rating', 'desc'),
      limit(limitCount)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Listing[];
  } catch (error: any) {
    console.error('Error getting featured listings:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw error;
  }
}

export async function getListingsByUser(userId: string) {
  try {
    const q = query(
      collection(db, 'listings'),
      where('owner.id', '==', userId),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Listing[];
  } catch (error: any) {
    console.error('Error getting user listings:', error);
    if (error instanceof FirestoreError && error.code === 'failed-precondition') {
      throw new Error('Database indexes are being created. Please try again in a few minutes.');
    }
    throw error;
  }
}