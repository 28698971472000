import {
  collection,
  addDoc,
  query,
  orderBy,
  getDocs,
  where,
  limit,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase';
import type { EventLog } from '../../types';

const logsCollection = collection(db, 'eventLogs');

export async function logEvent(data: Omit<EventLog, 'id' | 'timestamp'>) {
  try {
    const docRef = await addDoc(logsCollection, {
      ...data,
      timestamp: serverTimestamp(),
    });
    return docRef.id;
  } catch (error) {
    console.error('Failed to log event:', error);
  }
}

export async function getEventLogs(options?: {
  type?: EventLog['type'];
  limit?: number;
  userId?: string;
}) {
  try {
    let q = query(logsCollection, orderBy('timestamp', 'desc'));

    if (options?.type) {
      q = query(q, where('type', '==', options.type));
    }

    if (options?.userId) {
      q = query(q, where('userId', '==', options.userId));
    }

    if (options?.limit) {
      q = query(q, limit(options.limit));
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as EventLog[];
  } catch (error) {
    console.error('Failed to get event logs:', error);
    throw error;
  }
}