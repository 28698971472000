import React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { X, CheckCircle, AlertCircle } from 'lucide-react';

interface ToastProviderProps {
  children: React.ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  return (
    <ToastPrimitive.Provider>
      {children}
      <ToastPrimitive.Viewport className="fixed bottom-0 right-0 flex flex-col p-6 gap-2 w-full md:max-w-[420px] z-[9999] outline-none" />
    </ToastPrimitive.Provider>
  );
}

interface ToastProps {
  title?: string;
  description?: string;
  type?: 'success' | 'error';
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function Toast({ title, description, type = 'success', open, onOpenChange }: ToastProps) {
  return (
    <ToastPrimitive.Root
      open={open}
      onOpenChange={onOpenChange}
      className={`
        group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all
        ${type === 'error' 
          ? 'bg-red-50 border-red-200' 
          : 'bg-green-50 border-green-200'
        }
      `}
    >
      <div className="flex items-start gap-3">
        {type === 'error' ? (
          <AlertCircle className="h-5 w-5 text-red-500" />
        ) : (
          <CheckCircle className="h-5 w-5 text-green-500" />
        )}
        <div className="grid gap-1">
          {title && (
            <ToastPrimitive.Title className={`text-sm font-semibold ${
              type === 'error' ? 'text-red-900' : 'text-green-900'
            }`}>
              {title}
            </ToastPrimitive.Title>
          )}
          {description && (
            <ToastPrimitive.Description className={`text-sm ${
              type === 'error' ? 'text-red-700' : 'text-green-700'
            }`}>
              {description}
            </ToastPrimitive.Description>
          )}
        </div>
      </div>
      <ToastPrimitive.Close className="absolute right-2 top-2 rounded-md p-1 text-gray-500 opacity-0 transition-opacity hover:text-gray-900 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100">
        <X className="h-4 w-4" />
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
  );
}

export function useToast() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<Omit<ToastProps, 'open' | 'onOpenChange'>>({});
  
  const show = React.useCallback((props: Omit<ToastProps, 'open' | 'onOpenChange'>) => {
    setData(props);
    setOpen(true);
  }, []);

  return {
    Toast: (
      <Toast
        {...data}
        open={open}
        onOpenChange={setOpen}
      />
    ),
    show,
  };
}

export default Toast;