import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Building2, Mail, Globe, FileText, ArrowLeft, MapPin } from 'lucide-react';
import { nanoid } from 'nanoid';
import { createUnclaimedBusiness } from '../lib/db/users';
import AddressForm from '../components/AddressForm';
import toast from 'react-hot-toast';

const businessSchema = z.object({
  businessInfo: z.object({
    name: z.string().min(2, 'Business name must be at least 2 characters'),
    type: z.enum(['shop', 'rental', 'manufacturer', 'other'], {
      required_error: 'Please select a business type',
    }),
    website: z.string().url('Invalid website URL').optional(),
    email: z.string().email('Invalid email address'),
    phone: z.string().min(10, 'Phone number is required'),
    taxId: z.string().optional(),
  }),
  address: z.object({
    street: z.string().min(5, 'Street address is required'),
    unit: z.string().optional(),
    city: z.string().min(2, 'City is required'),
    state: z.string().min(2, 'State is required'),
    postalCode: z.string().min(3, 'Postal code is required'),
    country: z.string().min(2, 'Country is required'),
    coordinates: z.object({
      lat: z.number(),
      lng: z.number(),
    }),
    privacy: z.enum(['public', 'city_only']),
  }),
});

type BusinessInputs = z.infer<typeof businessSchema>;

function AdminCreateBusiness() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BusinessInputs>({
    resolver: zodResolver(businessSchema),
  });

  const onSubmit = async (data: BusinessInputs) => {
    const createPromise = async () => {
      setIsSubmitting(true);
      try {
        const claimToken = nanoid(32);
        const business = await createUnclaimedBusiness({
          accountType: 'business',
          role: 'user',
          status: 'pending',
          businessInfo: {
            ...data.businessInfo,
            claimed: false,
            claimToken,
          },
          address: data.address,
          listings: [],
          notificationPreferences: {
            email: true,
            sms: true,
          },
        });

        const claimUrl = `${window.location.origin}/claim/${business.id}/${claimToken}`;
        await navigator.clipboard.writeText(claimUrl);
        return claimUrl;
      } finally {
        setIsSubmitting(false);
      }
    };

    toast.promise(createPromise(), {
      loading: 'Creating business...',
      success: (claimUrl) => {
        navigate('/admin/users');
        return (
          <div className="flex flex-col space-y-2">
            <p>Business created successfully!</p>
            <p className="text-sm opacity-90">Claim URL copied to clipboard</p>
          </div>
        );
      },
      error: (err) => `Failed to create business: ${err.message}`,
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-600 hover:text-gray-900 mb-4"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back
          </button>
          <h1 className="text-2xl font-bold text-gray-900">Create Unclaimed Business</h1>
          <p className="mt-1 text-sm text-gray-500">
            Add a business to the directory that can be claimed later by its owner
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {/* Business Information */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex items-center space-x-3 mb-6">
              <Building2 className="w-6 h-6 text-primary-500" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Business Information</h2>
                <p className="text-sm text-gray-500">Basic details about the business</p>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Business Name
                </label>
                <input
                  {...register('businessInfo.name')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  placeholder="Enter business name"
                />
                {errors.businessInfo?.name && (
                  <p className="mt-1 text-sm text-red-600">{errors.businessInfo.name.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Business Type
                </label>
                <select
                  {...register('businessInfo.type')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                >
                  <option value="">Select business type</option>
                  <option value="shop">Bike Shop</option>
                  <option value="rental">Rental Service</option>
                  <option value="manufacturer">Manufacturer</option>
                  <option value="other">Other</option>
                </select>
                {errors.businessInfo?.type && (
                  <p className="mt-1 text-sm text-red-600">{errors.businessInfo.type.message}</p>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      {...register('businessInfo.email')}
                      type="email"
                      className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="contact@example.com"
                    />
                  </div>
                  {errors.businessInfo?.email && (
                    <p className="mt-1 text-sm text-red-600">{errors.businessInfo.email.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Website (Optional)
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Globe className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      {...register('businessInfo.website')}
                      type="url"
                      className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="https://example.com"
                    />
                  </div>
                  {errors.businessInfo?.website && (
                    <p className="mt-1 text-sm text-red-600">{errors.businessInfo.website.message}</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    {...register('businessInfo.phone')}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    placeholder="+1 (555) 000-0000"
                  />
                  {errors.businessInfo?.phone && (
                    <p className="mt-1 text-sm text-red-600">{errors.businessInfo.phone.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tax ID (Optional)
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FileText className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      {...register('businessInfo.taxId')}
                      className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="Tax ID or VAT number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Address Information */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex items-center space-x-3 mb-6">
              <MapPin className="w-6 h-6 text-primary-500" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Business Address</h2>
                <p className="text-sm text-gray-500">Physical location of the business</p>
              </div>
            </div>

            <AddressForm
              register={register}
              setValue={setValue}
              errors={errors}
            />
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Creating...' : 'Create Business'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminCreateBusiness;