import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { LayoutDashboard, ShoppingBag, Settings, Bell } from 'lucide-react';
import ListingsTab from './tabs/ListingsTab';
import SettingsTab from './tabs/SettingsTab';
import OverviewTab from './tabs/OverviewTab';
import NotificationsTab from './tabs/NotificationsTab';

const tabs = [
  { id: 'overview', label: 'Overview', icon: LayoutDashboard },
  { id: 'listings', label: 'Listings', icon: ShoppingBag },
  { id: 'notifications', label: 'Notifications', icon: Bell },
  { id: 'settings', label: 'Settings', icon: Settings },
];

const DashboardTabs: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultTab = searchParams.get('tab') || 'overview';
  const selectedListing = searchParams.get('listing');

  return (
    <Tabs.Root defaultValue={defaultTab} className="w-full">
      {/* Tab List */}
      <div className="bg-white border-b border-gray-200 sticky top-0 z-10">
        <div className="container mx-auto px-4">
          <Tabs.List className="flex space-x-1 overflow-x-auto">
            {tabs.map(({ id, label, icon: Icon }) => (
              <Tabs.Trigger
                key={id}
                value={id}
                className="group px-4 py-3 relative flex items-center space-x-2 text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none whitespace-nowrap"
              >
                <Icon className="w-5 h-5" />
                <span>{label}</span>
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary-500 scale-x-0 group-data-[state=active]:scale-x-100 transition-transform" />
              </Tabs.Trigger>
            ))}
          </Tabs.List>
        </div>
      </div>

      {/* Tab Content */}
      <div className="container mx-auto px-4 py-8">
        <Tabs.Content value="overview">
          <OverviewTab />
        </Tabs.Content>

        <Tabs.Content value="listings">
          <ListingsTab />
        </Tabs.Content>

        <Tabs.Content value="notifications">
          <NotificationsTab />
        </Tabs.Content>

        <Tabs.Content value="settings">
          <SettingsTab />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};

export default DashboardTabs;