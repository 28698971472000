import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Brand, Bag } from '../types';

interface BrandContextType {
  brands: Brand[];
  addBrand: (brand: Omit<Brand, 'id'>) => void;
  updateBrand: (id: string, brand: Omit<Brand, 'id'>) => void;
  deleteBrand: (id: string) => void;
}

const BrandContext = createContext<BrandContextType | undefined>(undefined);

export function BrandProvider({ children }: { children: React.ReactNode }) {
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    // Subscribe to brands collection
    const q = query(collection(db, 'brands'), orderBy('name', 'asc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedBrands = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Brand[];
      setBrands(updatedBrands);
    });

    return () => unsubscribe();
  }, []);

  const addBrand = async (brand: Omit<Brand, 'id'>) => {
    try {
      await addBrand(brand);
    } catch (error) {
      console.error('Error adding brand:', error);
      throw error;
    }
  };

  const updateBrand = async (id: string, data: Partial<Brand>) => {
    try {
      await updateBrand(id, data);
    } catch (error) {
      console.error('Error updating brand:', error);
      throw error;
    }
  };

  const deleteBrand = async (id: string) => {
    try {
      await deleteBrand(id);
    } catch (error) {
      console.error('Error deleting brand:', error);
      throw error;
    }
  };

  return (
    <BrandContext.Provider value={{ brands, addBrand, updateBrand, deleteBrand }}>
      {children}
    </BrandContext.Provider>
  );
}

export function useBrands() {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error('useBrands must be used within a BrandProvider');
  }
  return context;
}