import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Package, Plus, Edit2, Calendar, DollarSign, 
  BookOpen, Loader2, Star
} from 'lucide-react';
import ListingStatusToggle from '../../ListingStatusToggle';
import { useAuth } from '../../../context/AuthContext';
import { getListingsByUser, deleteListing } from '../../../lib/db/listings';
import type { Listing } from '../../../types';
import { usePrice } from '../../../utils/currency';
import toast from 'react-hot-toast';

const ListingsTab: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [listings, setListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { format: formatPrice } = usePrice();

  useEffect(() => {
    const loadListings = async () => {
      if (!user) return;
      try {
        const userListings = await getListingsByUser(user.id);
        setListings(userListings);
      } catch (error) {
        console.error('Error loading listings:', error);
        toast.error('Failed to load listings');
      } finally {
        setIsLoading(false);
      }
    };

    loadListings();
  }, [user]);

  const navigateToSection = (listingId: string, section: string) => {
    navigate(`/dashboard?tab=listings&listing=${listingId}&section=${section}`);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto" />
        <p className="mt-4 text-gray-500">Loading listings...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Your Listings</h2>
        <button
          onClick={() => navigate('/new-listing')}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Listing
        </button>
      </div>

      {listings.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm border border-gray-200">
          <Package className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Listings Yet</h3>
          <p className="text-gray-500 mb-6">
            Start earning by listing your bike bag for rent
          </p>
          <button
            onClick={() => navigate('/new-listing')}
            className="bg-primary-500 hover:bg-primary-600 text-white px-6 py-3 rounded-lg transition duration-200 inline-flex items-center space-x-2"
          >
            <Plus className="w-5 h-5" />
            <span>Create Your First Listing</span>
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          {listings.map((listing) => (
            <div
              key={listing.id}
              className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 relative"
              data-testid="listing-card"
            >
              {/* Status Toggle */}
              <div className="absolute top-4 right-4">
                <ListingStatusToggle
                  listingId={listing.id}
                  isLive={listing.status === 'live'}
                />
              </div>

              <div className="flex items-start justify-between mt-8">
                {/* Main Content */}
                <div className="flex-shrink-0">
                  <img
                    src={listing.images[listing.thumbnailIndex || 0]}
                    alt={listing.bagSpecs.model}
                    className="w-24 h-24 rounded-lg object-cover"
                  />
                </div>
                <div className="flex-1 min-w-0 ml-4">
                  <div className="flex items-center space-x-2">
                    <h3 className="text-lg font-semibold text-gray-900">
                      {listing.bagSpecs.model}
                    </h3>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/listing/${listing.id}/edit`);
                      }}
                      className="p-1 text-gray-400 hover:text-gray-600 rounded-lg transition-colors"
                      title="Edit listing"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                  </div>
                  <div className="mt-2 flex items-center space-x-4">
                    <div className="flex items-center text-sm">
                      <Star className="w-4 h-4 text-yellow-400 mr-1" />
                      <span className="font-medium text-gray-900">{listing.rating}</span>
                      <span className="text-gray-500 ml-1">({listing.reviews} reviews)</span>
                    </div>
                    <div className="text-sm font-medium text-gray-900">
                      {formatPrice(listing.pricingSchedule.dailyRate)}/day
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Action Menu */}
              <div className="flex flex-wrap justify-end gap-2 mt-4 md:mt-6">
                <button
                  onClick={() => navigateToSection(listing.id, 'availability')}
                  className="inline-flex items-center px-3 py-2 text-sm bg-gray-50 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <Calendar className="w-4 h-4 mr-1.5" />
                  <span>Availability</span>
                </button>
                <button
                  onClick={() => navigateToSection(listing.id, 'bookings')}
                  className="inline-flex items-center px-3 py-2 text-sm bg-gray-50 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <BookOpen className="w-4 h-4 mr-1.5" />
                  <span>Bookings</span>
                </button>
                <button
                  onClick={() => navigateToSection(listing.id, 'pricing')}
                  className="inline-flex items-center px-3 py-2 text-sm bg-gray-50 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <DollarSign className="w-4 h-4 mr-1.5" />
                  <span>Pricing</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListingsTab;