import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Package, Plus } from 'lucide-react';

const bagSchema = z.object({
  protectionLevel: z.number().min(1).max(5),
  transportRating: z.number().min(1).max(5),
  tsaCompliant: z.enum(['Yes', 'No', 'Unknown']),
  dimensions: z.object({
    length: z.number().min(0),
    width: z.number().min(0),
    height: z.number().min(0),
  }),
  weight: z.object({
    empty: z.number().min(0),
    maxLoad: z.number().min(0),
  }),
  wheelSize: z.object({
    min: z.number().min(20).max(29),
    max: z.number().min(20).max(29),
  }),
  wheelbase: z.object({
    max: z.number().min(50, 'Wheelbase must be at least 50cm').max(200, 'Wheelbase cannot exceed 200cm'),
  }),
  volume: z.number().min(0),
  manualUrl: z.string().url().optional(),
  packingGuideUrl: z.string().url().optional(),
  securityFeaturesText: z.string(),
});

type BagInputs = z.infer<typeof bagSchema>;

const BagManagement: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BagInputs>({
    resolver: zodResolver(bagSchema),
  });

  const onSubmit = async (data: BagInputs) => {
    console.log(data);
    // TODO: Implement bag creation/update logic
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Protection Level (1-5)
          </label>
          <input
            type="number"
            min="1"
            max="5"
            {...register('protectionLevel', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
          />
          {errors.protectionLevel && (
            <p className="mt-1 text-sm text-red-600">{errors.protectionLevel.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Transport Rating (1-5)
          </label>
          <input
            type="number"
            min="1"
            max="5"
            {...register('transportRating', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
          />
          {errors.transportRating && (
            <p className="mt-1 text-sm text-red-600">{errors.transportRating.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            TSA Compliant
          </label>
          <select
            {...register('tsaCompliant')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
      </div>

      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-2">Dimensions</h4>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Length (cm)
            </label>
            <input
              type="number"
              min="0"
              step="0.1"
              {...register('dimensions.length', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.dimensions?.length && (
              <p className="mt-1 text-sm text-red-600">{errors.dimensions.length.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Width (cm)
            </label>
            <input
              type="number"
              min="0"
              step="0.1"
              {...register('dimensions.width', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.dimensions?.width && (
              <p className="mt-1 text-sm text-red-600">{errors.dimensions.width.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Height (cm)
            </label>
            <input
              type="number"
              min="0"
              step="0.1"
              {...register('dimensions.height', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.dimensions?.height && (
              <p className="mt-1 text-sm text-red-600">{errors.dimensions.height.message}</p>
            )}
          </div>
        </div>
      </div>

      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-2">Weight</h4>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Empty Weight (g)
            </label>
            <input
              type="number"
              min="0"
              {...register('weight.empty', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.weight?.empty && (
              <p className="mt-1 text-sm text-red-600">{errors.weight.empty.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Maximum Load (g)
            </label>
            <input
              type="number"
              min="0"
              {...register('weight.maxLoad', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.weight?.maxLoad && (
              <p className="mt-1 text-sm text-red-600">{errors.weight.maxLoad.message}</p>
            )}
          </div>
        </div>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Maximum Wheelbase (cm)
        </label>
        <input
          type="number"
          min="50"
          max="200"
          step="1"
          {...register('wheelbase.max', { valueAsNumber: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
        />
        {errors.wheelbase?.max && (
          <p className="mt-1 text-sm text-red-600">{errors.wheelbase.max.message}</p>
        )}
      </div>

      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-2">Wheel Size</h4>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Minimum (inches)
            </label>
            <input
              type="number"
              min="20"
              max="29"
              {...register('wheelSize.min', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.wheelSize?.min && (
              <p className="mt-1 text-sm text-red-600">{errors.wheelSize.min.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Maximum (inches)
            </label>
            <input
              type="number"
              min="20"
              max="29"
              {...register('wheelSize.max', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            {errors.wheelSize?.max && (
              <p className="mt-1 text-sm text-red-600">{errors.wheelSize.max.message}</p>
            )}
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Volume (liters)
        </label>
        <input
          type="number"
          min="0"
          step="0.1"
          {...register('volume', { valueAsNumber: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
        />
        {errors.volume && (
          <p className="mt-1 text-sm text-red-600">{errors.volume.message}</p>
        )}
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Manual URL
          </label>
          <input
            type="url"
            {...register('manualUrl')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            placeholder="https://example.com/manual.pdf"
          />
          {errors.manualUrl && (
            <p className="mt-1 text-sm text-red-600">{errors.manualUrl.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Packing Guide URL
          </label>
          <input
            type="url"
            {...register('packingGuideUrl')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            placeholder="https://example.com/packing-guide"
          />
          {errors.packingGuideUrl && (
            <p className="mt-1 text-sm text-red-600">{errors.packingGuideUrl.message}</p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Security Features (one per line)
        </label>
        <textarea
          {...register('securityFeaturesText')}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
          placeholder="Enter security features (one per line)"
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Bag
        </button>
      </div>
    </form>
  );
};

export default BagManagement;