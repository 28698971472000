import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Package, AlertCircle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { FirebaseError } from 'firebase/app';

const signUpSchema = z.object({
  firstName: z.string().min(2, 'First name must be at least 2 characters'),
  lastName: z.string().min(2, 'Last name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

type SignUpInputs = z.infer<typeof signUpSchema>;

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignUpInputs>({
    resolver: zodResolver(signUpSchema),
  });

  const onSubmit = async (data: SignUpInputs) => {
    try {
      setAuthError(null);
      await signUp(data.email, data.password, data.firstName, data.lastName);
      navigate('/');
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            setAuthError('This email is already registered. Please sign in instead.');
            break;
          case 'auth/invalid-email':
            setAuthError('Invalid email address.');
            break;
          case 'auth/operation-not-allowed':
            setAuthError('Email/password accounts are not enabled. Please contact support.');
            break;
          case 'auth/weak-password':
            setAuthError('Password is too weak. Please choose a stronger password.');
            break;
          default:
            setAuthError('An error occurred during sign up. Please try again.');
        }
      } else {
        setAuthError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="py-8">
        <div className="container mx-auto px-4">
          <Link to="/" className="flex items-center justify-center space-x-2">
            <Package className="w-10 h-10 text-primary-500" />
            <span className="text-2xl font-bold text-gray-900">packmyride</span>
          </Link>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-1 flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-md">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-3">Create your account</h1>
            <p className="text-gray-600">
              Already have an account?{' '}
              <Link to="/sign-in" className="text-primary-500 hover:text-primary-600 font-medium">
                Sign in
              </Link>
            </p>
            <p className="text-gray-600 mt-2">
              Are you a business?{' '}
              <Link to="/business/sign-up" className="text-primary-500 hover:text-primary-600 font-medium">
                Create a business account
              </Link>
            </p>
          </div>

          {authError && (
            <div className="mb-6 bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
              <span>{authError}</span>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="form-label">First Name</label>
                <input
                  {...register('firstName')}
                  className="form-input"
                  placeholder="John"
                />
                {errors.firstName && (
                  <p className="form-error">{errors.firstName.message}</p>
                )}
              </div>

              <div>
                <label className="form-label">Last Name</label>
                <input
                  {...register('lastName')}
                  className="form-input"
                  placeholder="Doe"
                />
                {errors.lastName && (
                  <p className="form-error">{errors.lastName.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="form-label">Email</label>
              <input
                {...register('email')}
                type="email"
                className="form-input"
                placeholder="you@example.com"
              />
              {errors.email && (
                <p className="form-error">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label className="form-label">Password</label>
              <input
                {...register('password')}
                type="password"
                className="form-input"
                placeholder="••••••••"
              />
              {errors.password && (
                <p className="form-error">{errors.password.message}</p>
              )}
            </div>

            <div>
              <label className="form-label">Confirm Password</label>
              <input
                {...register('confirmPassword')}
                type="password"
                className="form-input"
                placeholder="••••••••"
              />
              {errors.confirmPassword && (
                <p className="form-error">{errors.confirmPassword.message}</p>
              )}
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn-primary"
            >
              {isSubmitting ? 'Creating account...' : 'Create Account'}
            </button>

            <p className="text-sm text-gray-500 text-center">
              By signing up, you agree to our{' '}
              <Link to="/terms" className="text-primary-500 hover:text-primary-600">
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link to="/privacy" className="text-primary-500 hover:text-primary-600">
                Privacy Policy
              </Link>
            </p>
          </form>
        </div>
      </main>

      {/* Footer */}
      <footer className="py-8">
        <div className="container mx-auto px-4 text-center text-sm text-gray-600">
          © {new Date().getFullYear()} packmyride. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default SignUp;