import React from 'react';
import { Building2, Package } from 'lucide-react';
import BrandManagement from '../components/BrandManagement';
import BagManagement from '../components/BagManagement';

function AdminBagManager() {
  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Package className="w-8 h-8 text-primary-500" />
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Bag Management</h2>
            <p className="text-gray-500">Manage bike bag specifications and details</p>
          </div>
        </div>
        <BagManagement />
      </div>
      
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Building2 className="w-8 h-8 text-primary-500" />
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Brand Management</h2>
            <p className="text-gray-500">Manage bike bag manufacturers</p>
          </div>
        </div>
        <BrandManagement />
      </div>
    </div>
  );
}

export default AdminBagManager;