import React from 'react';
import { Calendar, Clock, CheckCircle, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { useAuth } from '../../../context/AuthContext';
import { usePrice } from '../../../utils/currency';

// Mock data - replace with real data from your backend
const upcomingBookings = [
  {
    id: '1',
    renterName: 'John Smith',
    startDate: new Date(2024, 2, 20),
    endDate: new Date(2024, 2, 25),
    status: 'confirmed',
    bagModel: 'Atlas Bike Bag',
    totalPrice: 250,
  },
  {
    id: '2',
    renterName: 'Sarah Wilson',
    startDate: new Date(2024, 3, 1),
    endDate: new Date(2024, 3, 5),
    status: 'pending',
    bagModel: 'Pro Travel Case',
    totalPrice: 180,
  },
];

const recentBookings = [
  {
    id: '3',
    renterName: 'Mike Johnson',
    startDate: new Date(2024, 2, 1),
    endDate: new Date(2024, 2, 5),
    status: 'completed',
    bagModel: 'Atlas Bike Bag',
    totalPrice: 200,
    rating: 5,
  },
];

const OverviewTab: React.FC = () => {
  const { user } = useAuth();
  const { format: formatPrice } = usePrice();

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'confirmed':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'pending':
        return <AlertCircle className="w-5 h-5 text-yellow-500" />;
      case 'completed':
        return <CheckCircle className="w-5 h-5 text-blue-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-8">
      {/* Upcoming Bookings */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Calendar className="w-6 h-6 text-primary-500" />
          <div>
            <h3 className="text-lg font-semibold text-gray-900">Upcoming Bookings</h3>
            <p className="text-sm text-gray-500">Your next scheduled rentals</p>
          </div>
        </div>

        <div className="space-y-6">
          {upcomingBookings.map((booking) => (
            <div
              key={booking.id}
              className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex-shrink-0">
                {getStatusIcon(booking.status)}
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <h4 className="text-sm font-medium text-gray-900">
                    {booking.renterName}
                  </h4>
                  <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(booking.status)}`}>
                    {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500">{booking.bagModel}</p>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <Clock className="w-4 h-4 mr-1.5" />
                  <span>
                    {format(booking.startDate, 'MMM d')} - {format(booking.endDate, 'MMM d, yyyy')}
                  </span>
                </div>
                <div className="mt-2 text-sm font-medium text-gray-900">
                  {formatPrice(booking.totalPrice)}
                </div>
              </div>
            </div>
          ))}

          {upcomingBookings.length === 0 && (
            <div className="text-center py-6 text-gray-500">
              No upcoming bookings
            </div>
          )}
        </div>
      </div>

      {/* Recently Completed */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center space-x-3 mb-6">
          <CheckCircle className="w-6 h-6 text-primary-500" />
          <div>
            <h3 className="text-lg font-semibold text-gray-900">Recently Completed</h3>
            <p className="text-sm text-gray-500">Your past rentals</p>
          </div>
        </div>

        <div className="space-y-6">
          {recentBookings.map((booking) => (
            <div
              key={booking.id}
              className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex-shrink-0">
                {getStatusIcon(booking.status)}
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <h4 className="text-sm font-medium text-gray-900">
                    {booking.renterName}
                  </h4>
                  <div className="flex items-center">
                    {booking.rating && (
                      <div className="flex items-center mr-2">
                        <span className="text-yellow-400">★</span>
                        <span className="ml-1 text-sm text-gray-600">{booking.rating}</span>
                      </div>
                    )}
                    <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(booking.status)}`}>
                      {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                    </span>
                  </div>
                </div>
                <p className="mt-1 text-sm text-gray-500">{booking.bagModel}</p>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <Clock className="w-4 h-4 mr-1.5" />
                  <span>
                    {format(booking.startDate, 'MMM d')} - {format(booking.endDate, 'MMM d, yyyy')}
                  </span>
                </div>
                <div className="mt-2 text-sm font-medium text-gray-900">
                  {formatPrice(booking.totalPrice)}
                </div>
              </div>
            </div>
          ))}

          {recentBookings.length === 0 && (
            <div className="text-center py-6 text-gray-500">
              No completed bookings
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;