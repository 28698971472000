import React from 'react';
import { Link } from 'react-router-dom';
import { 
  ShoppingBag, 
  Users, 
  Package, 
  Settings, 
  Activity,
  ChevronDown,
  Building2
} from 'lucide-react';

const AdminMenu: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const menuItems = [
    {
      path: '/admin/listings',
      label: 'Listings',
      icon: ShoppingBag,
    },
    {
      path: '/admin/users',
      label: 'Users',
      icon: Users,
    },
    {
      path: '/admin/bags',
      label: 'Bags',
      icon: Package,
    },
    {
      path: '/admin/business/new',
      label: 'Create Business',
      icon: Building2,
    },
    {
      path: '/admin/config',
      label: 'Settings',
      icon: Settings,
    },
    {
      path: '/admin/logs',
      label: 'Event Logs',
      icon: Activity,
    },
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-1 text-gray-700 hover:text-gray-900 font-medium"
      >
        <span>Admin</span>
        <ChevronDown className="w-4 h-4" />
      </button>

      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-10"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg py-2 z-20 border border-gray-100">
            {menuItems.map((item) => {
              const Icon = item.icon;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className="flex items-center space-x-3 px-4 py-2.5 text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  onClick={() => setIsOpen(false)}
                >
                  <Icon className="w-5 h-5 text-gray-500" />
                  <span className="font-medium">{item.label}</span>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminMenu;