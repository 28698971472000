import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { 
  Building2, Globe, Pencil, Trash2, Loader2, 
  Calendar, Plus, X, MapPin, Link as LinkIcon
} from 'lucide-react';
import { createBrand, updateBrand, deleteBrand, listBrands } from '../lib/db/brands';
import type { Brand } from '../types';
import toast from 'react-hot-toast';

const brandSchema = z.object({
  name: z.string().min(2, 'Brand name must be at least 2 characters'),
  website: z.string().url('Must be a valid URL'),
  description: z.string().min(10, 'Description must be at least 10 characters'),
  headquarters: z.string().min(2, 'Headquarters location is required'),
  foundedYear: z.number().min(1800).max(new Date().getFullYear()),
  specialtiesText: z.string().min(1, 'At least one specialty is required'),
});

type BrandInputs = z.infer<typeof brandSchema>;

const BrandManagement: React.FC = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<BrandInputs>({
    resolver: zodResolver(brandSchema),
  });

  useEffect(() => {
    const loadBrands = async () => {
      try {
        const loadedBrands = await listBrands();
        setBrands(loadedBrands);
      } catch (error) {
        console.error('Failed to load brands:', error);
        toast.error('Failed to load brands');
      } finally {
        setIsLoading(false);
      }
    };

    loadBrands();
  }, []);

  const onSubmit = async (data: BrandInputs) => {
    try {
      const specialties = data.specialtiesText
        .split('\n')
        .map(s => s.trim())
        .filter(Boolean);

      const brandData = {
        name: data.name,
        website: data.website,
        description: data.description,
        headquarters: data.headquarters,
        foundedYear: data.foundedYear,
        specialties,
      };

      if (editingId) {
        await updateBrand(editingId, brandData);
        const updatedBrands = brands.map(brand => 
          brand.id === editingId ? { ...brand, ...brandData } : brand
        );
        setBrands(updatedBrands);
        setEditingId(null);
      } else {
        const newBrand = await createBrand(brandData);
        setBrands([...brands, newBrand]);
      }
      
      reset();
      setShowForm(false);
    } catch (error) {
      console.error('Failed to save brand:', error);
      toast.error('Failed to save brand');
    }
  };

  const handleEdit = (brand: Brand) => {
    setEditingId(brand.id);
    setValue('name', brand.name);
    setValue('website', brand.website || '');
    setValue('description', brand.description);
    setValue('headquarters', brand.headquarters);
    setValue('foundedYear', brand.foundedYear);
    setValue('specialtiesText', brand.specialties.join('\n'));
    setShowForm(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      try {
        await deleteBrand(id);
        setBrands(brands.filter(b => b.id !== id));
        if (editingId === id) {
          setEditingId(null);
          reset();
          setShowForm(false);
        }
      } catch (error) {
        console.error('Failed to delete brand:', error);
        toast.error('Failed to delete brand');
      }
    }
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <Loader2 className="w-12 h-12 text-primary-500 animate-spin mx-auto" />
        <p className="mt-4 text-gray-500">Loading brands...</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Brands</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage bike bag manufacturers and their details
          </p>
        </div>
        <button
          onClick={() => {
            setEditingId(null);
            reset();
            setShowForm(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Brand
        </button>
      </div>

      {/* Brand Form */}
      {showForm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setShowForm(false);
                      setEditingId(null);
                      reset();
                    }}
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <h3 className="text-xl font-semibold leading-6 text-gray-900">
                      {editingId ? 'Edit Brand' : 'Add New Brand'}
                    </h3>
                    <div className="mt-6">
                      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Brand Name
                          </label>
                          <input
                            {...register('name')}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            placeholder="Enter brand name"
                          />
                          {errors.name && (
                            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                          )}
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Website
                          </label>
                          <input
                            {...register('website')}
                            type="url"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            placeholder="https://example.com"
                          />
                          {errors.website && (
                            <p className="mt-1 text-sm text-red-600">{errors.website.message}</p>
                          )}
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <textarea
                            {...register('description')}
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            placeholder="Enter brand description"
                          />
                          {errors.description && (
                            <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
                          )}
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Headquarters
                            </label>
                            <input
                              {...register('headquarters')}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                              placeholder="City, Country"
                            />
                            {errors.headquarters && (
                              <p className="mt-1 text-sm text-red-600">{errors.headquarters.message}</p>
                            )}
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Founded Year
                            </label>
                            <input
                              type="number"
                              {...register('foundedYear', { valueAsNumber: true })}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                              placeholder="YYYY"
                            />
                            {errors.foundedYear && (
                              <p className="mt-1 text-sm text-red-600">{errors.foundedYear.message}</p>
                            )}
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Specialties (one per line)
                          </label>
                          <textarea
                            {...register('specialtiesText')}
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            placeholder="Enter specialties (one per line)"
                          />
                          {errors.specialtiesText && (
                            <p className="mt-1 text-sm text-red-600">{errors.specialtiesText.message}</p>
                          )}
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-600 sm:ml-3 sm:w-auto"
                          >
                            {isSubmitting ? 'Saving...' : editingId ? 'Update Brand' : 'Add Brand'}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setShowForm(false);
                              setEditingId(null);
                              reset();
                            }}
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Brands List */}
      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        <ul className="divide-y divide-gray-200">
          {brands.map((brand) => (
            <li key={brand.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center space-x-3">
                    <h2 className="text-xl font-semibold text-gray-900">
                      {brand.name}
                    </h2>
                    {brand.website && (
                      <a
                        href={brand.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary-500 hover:text-primary-600 inline-flex items-center text-sm"
                      >
                        <Globe className="w-4 h-4 mr-1" />
                        Website
                      </a>
                    )}
                  </div>
                  <div className="mt-1 flex items-center text-sm text-gray-500">
                    <MapPin className="w-4 h-4 mr-1" />
                    {brand.headquarters}
                    <span className="mx-2">•</span>
                    <Calendar className="w-4 h-4 mr-1" />
                    Founded {brand.foundedYear}
                  </div>
                  <p className="mt-2 text-sm text-gray-600">{brand.description}</p>
                  <div className="mt-3 flex flex-wrap gap-2">
                    {brand.specialties.map((specialty, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center rounded-full bg-primary-50 px-2 py-1 text-xs font-medium text-primary-700"
                      >
                        {specialty}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="ml-6 flex items-center space-x-3">
                  <button
                    onClick={() => handleEdit(brand)}
                    className="text-gray-400 hover:text-gray-500"
                    title="Edit brand"
                  >
                    <Pencil className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(brand.id)}
                    className="text-red-400 hover:text-red-500"
                    title="Delete brand"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {brands.length === 0 && (
          <div className="text-center py-12">
            <Building2 className="w-12 h-12 text-gray-400 mx-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No brands</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by adding a new brand.</p>
            <div className="mt-6">
              <button
                onClick={() => {
                  setEditingId(null);
                  reset();
                  setShowForm(true);
                }}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <Plus className="w-5 h-5 mr-2" />
                Add Brand
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandManagement;