import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ShoppingBag, MapPin, Star, DollarSign, Calendar, 
  Edit2, Trash2, Eye, Plus, Building2, Loader2
} from 'lucide-react';
import { FEATURED_LISTINGS, RECENT_LISTINGS } from '../data/listings';
import { useBrands } from '../context/BrandContext';
import { usePrice } from '../utils/currency';
import type { Listing } from '../types';
import EditListingModal from './EditListingModal';
import NewListingModal from './NewListingModal';
import { getUnclaimedBusinesses } from '../lib/db/users';
import toast from 'react-hot-toast';

const ListingManagement: React.FC = () => {
  const navigate = useNavigate();
  const [listings] = useState<Listing[]>([...FEATURED_LISTINGS, ...RECENT_LISTINGS]);
  const { brands } = useBrands();
  const { format: formatPrice } = usePrice();
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [showNewListingModal, setShowNewListingModal] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [unclaimedBusinesses, setUnclaimedBusinesses] = useState<any[]>([]);
  const [filter, setFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUnclaimedBusinesses = async () => {
      try {
        const businesses = await getUnclaimedBusinesses();
        setUnclaimedBusinesses(businesses);
      } catch (error) {
        console.error('Failed to load unclaimed businesses:', error);
        toast.error('Failed to load businesses');
      } finally {
        setIsLoading(false);
      }
    };

    loadUnclaimedBusinesses();
  }, []);

  const filteredListings = listings.filter(listing => {
    switch (filter) {
      case 'active':
        return listing.available;
      case 'inactive':
        return !listing.available;
      default:
        return true;
    }
  });

  const handleEdit = (listing: Listing) => {
    setSelectedListing(listing);
  };

  const handleDelete = (listingId: number) => {
    if (window.confirm('Are you sure you want to delete this listing? This action cannot be undone.')) {
      // TODO: Implement delete functionality
      console.log('Delete listing:', listingId);
    }
  };

  const handleView = (listingId: number) => {
    navigate(`/listing/${listingId}`);
  };

  const handleNewListing = () => {
    setShowNewListingModal(true);
  };

  const handleSelectBusiness = (businessId: string) => {
    setSelectedBusinessId(businessId);
    setShowNewListingModal(true);
  };

  return (
    <div className="max-w-7xl mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Listings</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage bike bag listings and their availability
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative group">
            <button
              onClick={() => setShowNewListingModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <Plus className="w-5 h-5 mr-2" />
              Add Listing
            </button>
          </div>
        </div>
      </div>

      {/* Filter Tabs */}
      <div className="mb-6">
        <nav className="flex space-x-4" aria-label="Listing filters">
          <button
            onClick={() => setFilter('all')}
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              filter === 'all'
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            All Listings ({listings.length})
          </button>
          <button
            onClick={() => setFilter('active')}
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              filter === 'active'
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Active ({listings.filter(l => l.available).length})
          </button>
          <button
            onClick={() => setFilter('inactive')}
            className={`px-3 py-2 text-sm font-medium rounded-md ${
              filter === 'inactive'
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Inactive ({listings.filter(l => !l.available).length})
          </button>
        </nav>
      </div>

      {/* Listings List */}
      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        <ul className="divide-y divide-gray-200">
          {filteredListings.map((listing) => (
            <li key={listing.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        src={listing.image}
                        alt={listing.bagSpecs.model}
                        className="w-16 h-16 rounded-lg object-cover"
                      />
                    </div>
                    <div>
                      <h2 className="text-xl font-semibold text-gray-900">
                        {listing.bagSpecs.brand} {listing.bagSpecs.model}
                      </h2>
                      <div className="mt-1 flex items-center text-sm text-gray-500">
                        <MapPin className="w-4 h-4 mr-1" />
                        {listing.location}
                        <span className="mx-2">•</span>
                        <Star className="w-4 h-4 text-yellow-400 mr-1" />
                        {listing.rating} ({listing.reviews} reviews)
                      </div>
                      <div className="mt-1 flex items-center space-x-4 text-sm">
                        <span className="text-gray-500">
                          <DollarSign className="w-4 h-4 inline mr-1" />
                          {formatPrice(listing.pricingSchedule.dailyRate)}/day
                        </span>
                        <span className="text-gray-500">
                          <Calendar className="w-4 h-4 inline mr-1" />
                          Min. {listing.pricingSchedule.minimumDays} days
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-6 flex items-center space-x-3">
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      listing.available
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {listing.available ? 'Available' : 'Unavailable'}
                  </span>
                  {listing.featured && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      Featured
                    </span>
                  )}
                  <button
                    onClick={() => handleView(listing.id)}
                    className="text-gray-400 hover:text-gray-500"
                    title="View listing"
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleEdit(listing)}
                    className="text-gray-400 hover:text-gray-500"
                    title="Edit listing"
                  >
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(listing.id)}
                    className="text-red-400 hover:text-red-500"
                    title="Delete listing"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {filteredListings.length === 0 && (
          <div className="text-center py-12">
            <ShoppingBag className="w-12 h-12 text-gray-400 mx-auto" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No listings</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by adding a new listing.</p>
            <div className="mt-6">
              <button
                onClick={handleNewListing}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <Plus className="w-5 h-5 mr-2" />
                Add Listing
              </button>
            </div>
          </div>
        )}
      </div>

      {/* New Listing Modal */}
      {showNewListingModal && (
        <NewListingModal
          onClose={() => {
            setShowNewListingModal(false);
            setSelectedBusinessId(null);
          }}
        />
      )}

      {/* Edit Modal */}
      {selectedListing && (
        <EditListingModal
          listing={selectedListing}
          onClose={() => setSelectedListing(null)}
          onSave={(updatedListing) => {
            // TODO: Implement save functionality
            console.log('Save listing:', updatedListing);
            setSelectedListing(null);
          }}
        />
      )}
    </div>
  );
};

export default ListingManagement;