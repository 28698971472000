import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MapPin, Star, Ruler, Briefcase, Calendar, DollarSign, CalendarDays, Edit2, Trash2 } from 'lucide-react';
import UserAvatar from './UserAvatar';
import type { Listing } from '../types';
import { usePrice } from '../utils/currency';

interface ListingCardProps {
  listing: Listing;
  view: 'grid' | 'list';
  showActions?: boolean;
  onEdit?: (listing: Listing) => void;
  onDelete?: (listingId: string) => void;
}

const ListingCard: React.FC<ListingCardProps> = ({ 
  listing, 
  view, 
  showActions = false,
  onEdit,
  onDelete
}) => {
  const { format: formatPrice } = usePrice();
  const navigate = useNavigate();
  const thumbnailImage = listing.images[listing.thumbnailIndex] || listing.images[0] || '/placeholder-bag.jpg';

  const handleAction = (action: string) => {
    navigate(`/dashboard/listing/${listing.id}/${action}`);
  };

  return (
    <Link 
      to={`/listing/${listing.id}`}
      className="group block bg-white rounded-xl overflow-hidden transition-all duration-200 hover:translate-y-[-2px] hover:shadow-lg border border-gray-100"
    >
      <div className="aspect-[4/3] overflow-hidden">
        <img
          src={thumbnailImage}
          alt={`${listing.bagSpecs.model} bike bag`}
          className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
          loading="lazy"
        />
      </div>
      <div className="p-4">
        {/* Brand and Model Title */}
        <div className="flex items-start justify-between mb-3">
          <div>
            <div className="text-sm text-gray-500 mb-1">{listing.bagSpecs.brand}</div>
            <h3 className="text-lg font-semibold text-gray-900">
              {listing.bagSpecs.model}
            </h3>
          </div>
          <div className="flex items-center text-gray-900">
            <span className="text-xl font-bold text-primary-500">
              {formatPrice(listing.pricingSchedule.dailyRate)}
            </span>
            <span className="text-sm text-gray-500 ml-1">/day</span>
          </div>
        </div>
        
        {/* Location */}
        <div className="mt-2 flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            <MapPin className="w-4 h-4 mr-1" />
            <span>{listing.location}</span>
          </div>
          <div className="flex items-center">
            <Briefcase className="w-4 h-4 mr-1" />
            <span>{listing.trips || 0} trips</span>
          </div>
        </div>
        
        {/* Owner and Reviews */}
        <div className="mt-3 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <UserAvatar
              size="sm"
              firstName={listing.owner.firstName}
              lastName={listing.owner.lastName}
              profileImage={listing.owner.profileImage}
            />
            <span className="text-sm text-gray-600">
              {listing.owner.firstName} {listing.owner.lastName?.[0]}.
            </span>
          </div>
          <div className="flex items-center text-sm text-gray-500">
            <Star className="w-4 h-4 fill-current text-yellow-400 mr-1" />
            <span>{listing.rating}</span>
            <span className="mx-1">·</span>
            <span>{listing.reviews} reviews</span>
          </div>
        </div>
        
        {/* Specs and Stats */}
        {listing.bagSpecs.wheelbase?.max && (
          <div className="mt-3 flex items-center text-sm text-gray-500">
            <Ruler className="w-4 h-4 mr-1" />
            <span>Max Wheelbase: {listing.bagSpecs.wheelbase.max}cm</span>
          </div>
        )}
      </div>
      
      {showActions && (
        <div className="mt-4 flex justify-end space-x-2">
          {/* Management Actions */}
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAction('availability');
            }}
            className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors"
          >
            <Calendar className="w-4 h-4 mr-1.5" />
            Availability
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAction('pricing');
            }}
            className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors"
          >
            <DollarSign className="w-4 h-4 mr-1.5" />
            Pricing
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAction('bookings');
            }}
            className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors"
          >
            <CalendarDays className="w-4 h-4 mr-1.5" />
            Bookings
          </button>
          
          {/* Edit/Delete Actions */}
          {onEdit && (
            <button
              title="Edit listing"
              onClick={(e) => {
                e.preventDefault();
                onEdit(listing);
              }}
              className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors"
            >
              <Edit2 className="w-4 h-4 mr-1.5" />
              Edit
            </button>
          )}
          {onDelete && (
            <button
              title="Delete listing"
              onClick={(e) => {
                e.preventDefault();
                onDelete(listing.id);
              }}
              className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
            >
              <Trash2 className="w-4 h-4 mr-1.5" />
              Delete
            </button>
          )}
        </div>
      )}
    </Link>
  );
};

export default ListingCard;