import React, { useState } from 'react';
import * as Switch from '@radix-ui/react-switch';
import { Loader2 } from 'lucide-react';
import { updateListing } from '../lib/db/listings';
import toast from 'react-hot-toast';

interface ListingStatusToggleProps {
  listingId: string;
  isLive: boolean;
  onStatusChange?: (isLive: boolean) => void;
}

const ListingStatusToggle: React.FC<ListingStatusToggleProps> = ({
  listingId,
  isLive,
  onStatusChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(isLive);

  const handleStatusChange = async (checked: boolean) => {
    setIsLoading(true);
    try {
      // Optimistic update
      setCurrentStatus(checked);
      onStatusChange?.(checked);

      await updateListing(listingId, {
        status: checked ? 'live' : 'draft',
        updatedAt: new Date().toISOString(),
      });

      toast.success(`Listing is now ${checked ? 'live' : 'in draft'}`);
    } catch (error) {
      // Revert on error
      setCurrentStatus(!checked);
      onStatusChange?.(!checked);
      toast.error('Failed to update listing status');
      console.error('Error updating listing status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <Switch.Root
        checked={currentStatus}
        onCheckedChange={handleStatusChange}
        disabled={isLoading}
        className={`relative w-11 h-6 rounded-full transition-colors outline-none ${
          currentStatus ? 'bg-green-500' : 'bg-gray-300'
        } disabled:opacity-50 focus:ring-2 focus:ring-offset-2 ${
          currentStatus ? 'focus:ring-green-500/50' : 'focus:ring-gray-400/50'
        }`}
        aria-label="Toggle listing status"
      >
        <Switch.Thumb
          className={`block w-4 h-4 bg-white rounded-full transition-transform duration-200 ${
            currentStatus ? 'translate-x-6' : 'translate-x-1'
          }`}
        />
      </Switch.Root>
      <span className="text-sm font-medium text-gray-700">
        {isLoading ? (
          <div className="flex items-center space-x-2">
            <Loader2 className="w-4 h-4 animate-spin" />
            <span>Updating...</span>
          </div>
        ) : (
          currentStatus ? 'Live' : 'Draft'
        )}
      </span>
    </div>
  );
};

export default ListingStatusToggle;